export const TABS = [
  { id: "asset-classes", name: "Asset Classes", value: "0" },
  { id: "correlation-matrix", name: "Correlation Matrix", value: "1" },
  { id: "asset-groups", name: "Asset Groups", value: "2" },
  { id: "range-policies", name: "Range Policies", value: "3" },
  { id: "modules", name: "Modules", value: "4" },
  // { id: "database-wide-module", name: "Database-Wide Module", value: "4" },
  // { id: "clients", name: "Clients", value: "5" },
  // { id: "client groups", name: "Client groups", value: "6" },
  // { id: "tilting-scores", name: "Tilting Scores", value: "7" },
  // { id: "board-members", name: "Board Members", value: "8" },
];
