import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";

import Box from "@mui/material/Box";
import { DoFetch } from "../service/fetchConfig";

/*const [userLoginDetails, setUserLoginDetails] = useState<
{
  name: string;
  id: number;
  db_group_id?: number;
}[]
>([]);*/

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    // localStorage.setItem("token", response.data.token);
    localStorage.setItem("authUsername", 'User Profile');
    setLoading(true);
    /*DoFetch({
      // url: "/userlogincontroller/VerifyLogin",
      url: "/TokenController",
      anonymous: true,
      method: "POST",
      body: JSON.stringify({
        userName: auth.username,
        password: auth.password,
      }),
    }).then((response) => {
      console.log(response.data);
      if (response.data) {
        // auth,
        if (response.data.userName != "") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "authUsername",
            response.data.userFirstName + " " + response.data.userLastName
          );
          // location.state ? (location.state as any).nextPathname : '/'
          login(
            auth,
            location.state ? (location.state as any).nextPathname : "/"
          ).catch((error: Error) => {
            setLoading(false);
            notify(
              typeof error === "string"
                ? error
                : typeof error === "undefined" || !error.message
                ? "ra.auth.sign_in_error"
                : error.message,
              {
                type: "warning",
                messageArgs: {
                  _:
                    typeof error === "string"
                      ? error
                      : error && error.message
                      ? error.message
                      : undefined,
                },
              }
            );
          });
          // return <Navigate replace to="/" />;
        } else {
          setLoading(false);
          notify(
            "Username / password combination is incorrect. please try again.",
            {
              type: "warning",
            }
          );
        }
      }
    });*/

    login(
        auth,
        location.state ? (location.state as any).nextPathname : '/'
    ).catch((error: Error) => {
        setLoading(false);
        notify(
            typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                : error.message,
            {
                type: 'warning',
                messageArgs: {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                            ? error.message
                            : undefined,
                },
            }
        );
    });

  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          // background:
          //     'url(https://source.unsplash.com/random/1600x900)',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: "6em" }}>
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
              fontSize: "26px",
            }}
          >
            <h1>PortNox</h1>
          </Box>
          <Box
            sx={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "center",
              color: (theme) => theme.palette.grey[500],
              fontSize: "24px",
            }}
          >
            <span>App 01 - Rel 01</span>
          </Box>
          <Box sx={{ padding: "0 1em 1em 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                autoFocus
                source="username"
                label={translate("ra.auth.username")}
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                source="password"
                label={translate("ra.auth.password")}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: "0 1em 1em 1em" }}>
            <Button
              variant="contained"
              type="submit"
              color="inherit"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  username?: string;
  password?: string;
}
