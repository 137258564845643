import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardActions, Typography, Grid, List } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Modal, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper } from "@mui/material";
import "./moduleEdit.css";
import { LineChart, BarChart, Label, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Subefficientfrontier() {

  const chartData = [
    {
      name: 1.10,
      MER: 1.20,
    },
    {
      name: 5.02,
      MER: 6.00,
    },
    {
      name: 8.94,
      MER: 5.5,
    },
    {
      name: 12.86,
      MER: 5.04,
    },
    {
      name: 16.78,
      MER: 4.12,
    },
    {
      name: 20.70,
      MER: 3.12,
    }
  ];

  const graphGeneraInfoData = [
    {
      id: 1,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 2,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 3,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    }
  ];

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const stylesens = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }


  const [open, setOpen] = React.useState(false);
  const [term, setTerm] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const termHandleChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };

  return (

    <div>
      <Button
        variant="outlined" sx={{ color: "black", mt: 2, mr: 1 }} onClick={handleClickOpen}>
        Choose from Efficient Frontier
      </Button>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >

        <Box sx={style}>
          <h3>Efficient Frontier with Current Budget Contraints</h3>
          <Grid container spacing={4} style={{ marginTop: "30px" }}>
            <Grid item xs={6} className="wrapGrids" spacing={1}>

              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>


                <div className="chartSection">
                  <LineChart
                    width={400}
                    height={400}
                    data={chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 0,
                      bottom: 15,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 0" />
                    <XAxis dataKey="name" >
                      <Label value="Standard Deviation (%)" offset={1} position="insideBottom" dy={15} />
                    </XAxis>
                    <YAxis dataKey="MER" label={{ value: 'Minimum Expected Return (%)', angle: -90, position: 'Left' }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Line type="monotone" dataKey="MER" stroke="#8F8F8F" strokeWidth={3} activeDot={{ r: 4 }} />
                  </LineChart>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginLeft: "100px" }} className="grid-form">

              <div className="form-check" >
                <label className='frontier-form-label'>Exp. Return</label>
                <input className='frontier-form-fields' type="text" />
              </div>
              <div className="form-check" >
                <label className='frontier-form-label'>Volatility</label>
                <input className='frontier-form-fields' type="text" />
              </div>
              <div className="form-check" >
                <label className='frontier-form-label'>Liquidity</label>
                <input className='frontier-form-fields' type="text" />
              </div>
              <div className="form-check" >
                <label className='frontier-form-label'>EMR</label>
                <input className='frontier-form-fields' type="text" />
              </div>
              <div className="form-check" >
                <label className='frontier-form-label'>EMD</label>
                <input className='frontier-form-fields' type="text" />
              </div>

              <TableContainer
                component={Paper}
                style={{ marginTop: "10px", border: "none" }}
              >
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "grey" }} className="alltable-header-style-sml">
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Asset</TableCell>
                      <TableCell style={{ color: "white" }}>Weight</TableCell>
                      <TableCell style={{ color: "white" }}>LB</TableCell>
                      <TableCell style={{ color: "white" }}>UB</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {graphGeneraInfoData.map((indv: any, indxx: number) => {
                      return (
                        <TableRow style={{ height: "50px" }}>
                          <TableCell>{indv.asset}</TableCell>
                          <TableCell>{indv.weight}</TableCell>
                          <TableCell>{indv.lb}</TableCell>
                          <TableCell>{indv.ub}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {children} */}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Modal>

    </div>

  );
}



