import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Optimised = () => {
  
  return (
    <>




<Card style={{ marginTop: "10px" }}>
      <Box display="flex">
        <Box flex="1">
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            style={{ padding: "10px 10px" }}
          >
           Optimised Portfolio
            </Typography>
          <Grid sx={{ ml: 2, mr: 2,mt:-2 }}>
            <List dense={false}>
              <Grid container spacing={1}>
                <Grid item xs={2.4}>

                  <FormControl>
                    <label>Exp Return</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
               <Grid item xs={2.4}>

                  <FormControl>
                    <label>MR</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>

<Grid item xs={2.4}>

                  <FormControl>
                    <label>Volatility</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
    <Grid item xs={2.4}>

                  <FormControl>
                    <label>EMD</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>            
 <Grid item xs={2.4}>

                  <FormControl>
                    <label>Liquidity</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>       
               
              </Grid>


            </List>
          </Grid>
        </Box>
      </Box>
    </Card>


    </>
  );
};

export default Optimised;



//       <Card style={{ marginTop: "10px" }}>
//         <Box display="flex">
//           <Box flex="1">
//             <Typography
//               sx={{ ml: 8 }}
//               variant="h6"
//               component="h2"
//               gutterBottom
//               style={{ padding: "10px 10px" }}
//             >
//               Optimised Portfolio
//             </Typography>


             
//             <Grid sx={{ ml: 3, mr: 3 }}>
//               <List dense={false}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={2}>
                   
//                    <Form>
//                    <label>Exp Return</label>
//                   <TextInput source="" fullWidth />
//                    </Form>
//                   </Grid>
//                   <Grid item xs={2}>
//                   <Form>
//                    <label>MR</label>
//                   <TextInput source="" fullWidth />
//                    </Form>
//                   </Grid>
//                   <Grid item xs={2}>
//                   <Form>
//                    <label>Volatility</label>
//                   <TextInput source="" fullWidth />
//                    </Form>
//                   </Grid>
                 
                 

//  <Grid item xs={2}>
//                   <Form>
//                    <label>EMD</label>
//                   <TextInput source="" fullWidth />
//                    </Form>
//                   </Grid>
                 
                
//                    <Grid item xs={2}>
//                   <Form>
//                    <label>Liquidity</label>
//                   <TextInput source="" fullWidth />
//                    </Form>
//                   </Grid>
// <h4 style={{marginLeft:"10px"}}>Status: Optimized</h4> 
                
//                 </Grid>

               
//               </List>
//             </Grid>
         
          
//           </Box>
//         </Box>
//       </Card>

          

           
          