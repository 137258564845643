import { TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";

export const RangePlcyTableBody = ({ array, setArray }: any) => {
  const [editRangePlcyValue, setRangePlcyEditValue] = useState("");
  const [showRangePlcyEditIcon, setShowRangePlcyEditIcon] = useState("");
  const [editRangePlcyField, setRangePlcyEditField] = useState("");

  return (
    <TableBody>
      {array &&
        array.length > 0 &&
        array.map((row: any, indx: number) => {
          if (indx >= 2)
            return (
              <TableRow key={indx}>
                  {/* <TableCell>{indx}</TableCell> */}
                  {row.map((indv: any, indxx: number) => {
                    return (
                      indxx >= -1 && (
                        <TableCell
                          onClick={() => {
                            setRangePlcyEditField(indx + "-" + indxx);
                            setRangePlcyEditValue(indv);
                          }}
                          onMouseOver={() =>
                            setShowRangePlcyEditIcon(indx + "-" + indxx)
                          }
                          onMouseLeave={() => setShowRangePlcyEditIcon("")}
                          id={indx + "-" + indxx}
                        >
                          {/* {indv} */}
                          {editRangePlcyField === indx + "-" + indxx &&
                          editRangePlcyValue ? (
                            <ReturnInputField
                              id={indx + "-" + indxx}
                              initialValue={editRangePlcyValue}
                              returnChange={(changedValue) =>
                                setRangePlcyEditValue(changedValue)
                              }
                              getEventValueAndResetEditField={(
                                bluredValue: string
                              ) => {
                                const tempRangePlcyJsonData = [...array];
                                tempRangePlcyJsonData[indx][indxx] = bluredValue;
                                setArray(tempRangePlcyJsonData);
                                setRangePlcyEditField("");
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {indv}
                              {showRangePlcyEditIcon === indx + "-" + indxx && (
                                <div className="editIcon">
                                  <EditIcon
                                    onClick={() => {
                                      setRangePlcyEditField(indx + "-" + indxx);
                                      setRangePlcyEditValue(indv);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
            );
        })}
    </TableBody>
  );
};
