import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Card,
  CardActions,
  Grid,
  Modal,
  Tab,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Link,
  CreateButton,
  useListContext,
  useTranslate,
} from "react-admin";
import { useParams } from "react-router";
import { history } from "../App";
import Mainmenu from "../components/Mainmenu";
import { useOrderList } from "../hooks/useOrderList";
import { TABS } from "../utils/tabConstants";
import { AssetGrpTableBody } from "./AssetGrpTableBody";
import { AssetsClassTableBody } from "./AssetsClassTableBody";
import Buttons from "./Buttons";
import { CorrTableBody } from "./CorrTableBody";
import { ModulesTableBody } from "./ModulesTableBody";
import { RangePlcyTableBody } from "./RangePlcyTableBody";
import { TableTabMatrix } from "./TableTabMatrix";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import "./guide.css";

export const TabbedDatagrid = () => {
  const translate = useTranslate();
  const listContext = useListContext();
  const {
    useGetTotals,
    handleFile,
    assetGroupJsonDataArr,
    assetsJsonDataArr,
    correlationJsonDataArr,
    fileName,
    rangePolicyJsonDataArr,
    modulesJsonDataArr,
    setAssetsJsonDataArr,
    loadFile,
  } = useOrderList();
  const [currentTab, setCurrentTab] = useState("asset-classes");
  let params = useParams();
  console.log('data inside tabbed data griod section');
  console.log(correlationJsonDataArr);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { filterValues, setFilters, displayedFilters } = listContext;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const totals = useGetTotals(filterValues) as any;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: any) => {
      history.push("/" + newValue, newValue);
      setFilters &&
        setFilters(
          { ...filterValues, status: newValue },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  history.listen((update) => {
    if (update.location.state) setCurrentTab(String(update.location.state));
  });
  useEffect(() => {
    if (params.tab) setCurrentTab(params.tab);
  }, [params]);

  return (
    <Fragment>
      <Card style={{ marginTop: "20px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              style={{ padding: "20px 20px" }}
            >
              {translate("pos.globalData.header.title")}
            </Typography>
          </Box>
          <Mainmenu />
        </Box>
      </Card>

      <div style={{ width: "100%" }}>
        {/* <Buttons /> */}

        <div style={{ float: "right", margin: "20px" }}>
      <CardActions
        sx={{
          padding: { xs: 0, xl: null },
          flexWrap: { xs: "wrap", xl: null },
          "& a": {
            marginTop: { xs: "1em", xl: null },
            marginLeft: { xs: "0!important", xl: null },
            marginRight: { xs: "1em", xl: null },
          },
        }}
      >
        <Button
          sx={{ color: "black" }}
          variant="outlined"
          href="#"
          startIcon={<CreateNewFolderIcon />}
        >
          {translate("pos.globalData.buttons.create_and_proceed")}
        </Button>

        <Button
          sx={{ color: "black" }}
          onClick={handleClickOpen}
          variant="outlined"
          href="#"
          startIcon={<ImportExportIcon />}
        >
          {translate("pos.globalData.buttons.import_from_excel_btn")}
        </Button>

        <Button
          sx={{ color: "black" }}
          variant="outlined"
          href="#"
          startIcon={<CloudDownloadIcon />}
        >
          {translate("pos.globalData.buttons.export_to_excel_btn")}
        </Button>

        <Button
          sx={{ color: "black" }}
          variant="outlined"
          href="#"
          startIcon={<CloseIcon />}
          onClick={handleClose} component={Link} to="/"
        >
          {translate("pos.globalData.buttons.close_btn")}
        </Button>

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <h3>Asset Database FIle Upload</h3>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                style={{ margin: "20px", marginBottom: "-30px" }}
                className="grid-form"
              >
                
                <label htmlFor="images" className="drop-container">
                  <span className="drop-title">Drop files here</span>
                  or
                  <TextField
                    id="images"
                    // label="Outlined"
                    // variant="outlined"
                    onChange={(e) => handleFile(e)}
                    type="file"
                    // disabled={loadFile}
                    placeholder={"Browse Excel File Here"}
                    style={{
                      width: "400px",
                      height: "50px !important",
                    }}
                  />

{/* <TextField
                    id="outlined-basic"
                    label="Outlined"
                    // variant="outlined"
                    onChange={(e) => handleFile(e)}
                    type="file"
                    placeholder={"Browse Excel File Here"}
                    style={{
                      width: "400px"
                    }}
                  /> */}
                </label>
                <div className="modal-button-group">
                  <Button
                    sx={{ color: "black" }}
                    variant="outlined"
                    href="#"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                  >
                    {translate("pos.globalData.buttons.close_btn")} 
                  </Button>

                  {/* <Button
                    sx={{ color: "black" }}
                    variant="outlined"
                    href="#"
                    startIcon={<SaveIcon />} 
                    onClick={handleClose}
                  >
                    {translate("pos.globalData.buttons.submit")}
                  </Button> */}
                  
                </div>
                
              </Grid>
            </Grid>
          </Box>
        </Modal>



      </CardActions>
      
        
    </div>
        
        {/* <CreateButton
          // disabled={!loadFile}
          label="Create and Proceed"
          style={{
            textAlign: "right",
            float: "right",
            paddingTop: "20px",
            paddingRight: "50px",
          }}
          // onClick={() => {
          //   alert("Sorry. Could not complete the process !");
          // }}
        />  
        <Button
          style={{
            textAlign: "right",
            float: "right",
            paddingTop: "20px",
            paddingRight: "50px",
          }}
        >
          Create and Proceed
        </Button> */}

      </div>

      <TabContext value={currentTab}>
        <TabList
          className="tab-special"
          variant="fullWidth"
          centered
          value={currentTab}
          indicatorColor="secondary"
          onChange={handleChange}
        >
          {TABS.map((choice) => (
            <Tab
              key={choice.id}
              label={
                totals[choice.name]
                  ? `${choice.name} (${totals[choice.name]})`
                  : choice.name
              }
              value={choice.id}
            />
          ))}
        </TabList>

        <TabPanel value={"asset-classes"}>
          <TableTabMatrix array={assetsJsonDataArr || []} startIndex={3}>
            <AssetsClassTableBody
              array={assetsJsonDataArr || []}
              setArray={(val: any) => {
                setAssetsJsonDataArr(val);
              }}
            />
          </TableTabMatrix>
        </TabPanel>
        <TabPanel value={"correlation-matrix"}>
          <TableTabMatrix array={correlationJsonDataArr || []} startIndex={1}>
            <CorrTableBody array={correlationJsonDataArr || []} />
          </TableTabMatrix>
        </TabPanel>
        <TabPanel value={"asset-groups"}>
          <TableTabMatrix array={assetGroupJsonDataArr || []} startIndex={1}>
            <AssetGrpTableBody array={assetGroupJsonDataArr || []} />
          </TableTabMatrix>
        </TabPanel>
        <TabPanel value={"range-policies"}>
          <TableTabMatrix array={rangePolicyJsonDataArr || []} startIndex={0}>
            <RangePlcyTableBody array={rangePolicyJsonDataArr || []} />
          </TableTabMatrix>
        </TabPanel>
        <TabPanel value={"modules"}>
          {/* <TableTabMatrix array={modulesJsonDataArr || []} startIndex={0}> */}
          <ModulesTableBody array={modulesJsonDataArr || []} />
          {/* </TableTabMatrix> */}
        </TabPanel>
      </TabContext>
    </Fragment>
  );
};
