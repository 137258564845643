import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, CardActions,  Typography, Grid, List } from "@mui/material";
import {
  useMediaQuery,
  Theme,
  
 
 
  FormControl,
  FormLabel,
  
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";




export default function Addnewconstraint() {
  

  const [open, setOpen] = React.useState(false);
  const [term, setTerm] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const termHandleChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };

  return (

    
    <div>
       <Button className='btn-addconstraint'
       variant="outlined" sx={{color:"black",mt:2,mr:2}} onClick={handleClickOpen}>
        Add New Constraints
      </Button> 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add New Constraint"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          

          <FormControl>
                  <FormLabel>Create for an  Asset CLASS</FormLabel>
                  <Select
                    className="addconstraint-drop"
                    labelId="demo-simple-select-label"
                    id="outlined-basic"
                    // variant="outlined"
                    value={term}
                    label="Age"
                    onChange={termHandleChange}
                  >
                    <MenuItem value={10}>value 1</MenuItem>
                  </Select>
                </FormControl>
<br></br>
                <FormControl>
                  <FormLabel>Create for an  Asset Group</FormLabel>
                  <Select
                     className="addconstraint-drop"
                    labelId="demo-simple-select-label"
                    id="outlined-basic"
                    // variant="outlined"
                    value={term}
                    label="Age"
                    onChange={termHandleChange}
                  >
                    <MenuItem value={10}>value 1</MenuItem>
                  </Select>
                </FormControl>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
}