import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import AppsIcon from '@mui/icons-material/Apps';
import {Link } from 'react-router-dom'

export default function Mainmenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {/* <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}

            <IconButton sx={{mr:4, mt:2, fontSize: 40}}
                aria-label="more"
                
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >

                < AppsIcon />
            </IconButton>
            <Menu 
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose} component={Link} to="/">Open Asset Database</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/commands">Create Asset Database Group</MenuItem>
                {/*<MenuItem onClick={handleClose}>Datafile Backup</MenuItem>
                <MenuItem onClick={handleClose}>Datafile Restore</MenuItem>*/}
                <MenuItem onClick={handleClose}>Import Asset Database Group</MenuItem>
                <MenuItem onClick={handleClose}>Export Asset Database Group</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/goalEditor">Client Summary</MenuItem>

            </Menu>
        </div>
    );
}
