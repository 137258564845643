import { TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";

export const AssetsClassTableBody = ({ array, setArray }: any) => {
  const [editValue, setEditValue] = useState("");
  const [showEditIcon, setShowEditIcon] = useState("");
  const [editField, setEditField] = useState("");

  return (
    <TableBody>
      {array.length > 0 &&
        array.map((row: any, indx1: number) => {
          if (indx1 >= 4)
            return (
              <TableRow key={indx1}>
                <TableCell>{indx1}</TableCell>
                {row.map((indv: any, indxx1: number) => {
                  return (
                    indxx1 >= 1 && (
                      <TableCell
                        key={indxx1}
                        onClick={() => {
                          setEditField(indx1 + "-" + indxx1);
                          setEditValue(indv);
                        }}
                        onMouseOver={() =>
                          setShowEditIcon(indx1 + "-" + indxx1)
                        }
                        onMouseLeave={() => setShowEditIcon("")}
                        id={indx1 + "-" + indxx1}
                      >
                        {editField === indx1 + "-" + indxx1 && editValue ? (
                          <ReturnInputField
                            id={indx1 + "-" + indxx1}
                            initialValue={editValue}
                            returnChange={(changedValue) =>
                              setEditValue(changedValue)
                            }
                            getEventValueAndResetEditField={(
                              bluredValue: string
                            ) => {
                              const tempassetsJsonData = [...array];
                              tempassetsJsonData[indx1][indxx1] = bluredValue;
                              setArray(tempassetsJsonData);
                              setEditField("");
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {indv}
                            {showEditIcon === indx1 + "-" + indxx1 && (
                              <div className="editIcon">
                                <EditIcon
                                  onClick={() => {
                                    setEditField(indx1 + "-" + indxx1);
                                    setEditValue(indv);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            );
        })}
    </TableBody>
  );
};
