import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  styled,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "./guide.css";
import {
  LineChart,
  BarChart,
  Label,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";
import {Link } from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const ModulesTableBody = ({ array, setArray }: any) => {
  const [editModulesValue, setModulesEditValue] = useState("");
  const [showModulesEditIcon, setShowModulesEditIcon] = useState("");
  const [editModulesField, setModulesEditField] = useState("");
  const [open, setOpen] = React.useState(false);

  const moduleDisplayOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const modulesJsonData = [
    {
      name: "Module 23",
      status: "Not Optimized",
      exp_return: "0.00%",
      volatility: "0.00%",
      after_tax: "FALSE",
      last_saved: "2022-09-23 06:03:28",
    },
    {
      name: "Module 24",
      status: "Optimized",
      exp_return: "969.00%",
      volatility: "1090.00%",
      after_tax: "FALSE",
      last_saved: "2022-09-14 11:58:31",
    },
  ];

  const graphGeneraInfoData = [
    {
      id: 1,
      name: "CP Nominal AT",
      exp_return: "5.56%",
      volatility: "7.24%",
      tags: "",
    },
    {
      id: 1,
      name: "CP Nominal AT",
      exp_return: "5.56%",
      volatility: "7.24%",
      tags: "",
    },
    {
      id: 1,
      name: "CP Nominal AT",
      exp_return: "5.56%",
      volatility: "7.24%",
      tags: "",
    },
  ];

  const chartData = [
    { x: 1.87, y: 1.8 },
    { x: 4.2, y: 3.5 },
    { x: 5.37, y: 4.8 },
    { x: 7.71, y: 6 },
    { x: 10.05, y: 6.3 },
    { x: 13.55, y: 6.6 },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: "grey", color: "white" }}>
            <TableRow style={{ color: "white" }}>
              <TableCell style={{ color: "white" }}>Name</TableCell>
              <TableCell style={{ color: "white" }}>Status</TableCell>
              <TableCell style={{ color: "white" }}>Exp. Return</TableCell>
              <TableCell style={{ color: "white" }}>Volatility</TableCell>
              <TableCell style={{ color: "white" }}>After tax</TableCell>
              <TableCell style={{ color: "white" }}>Last Saved</TableCell>
              <TableCell style={{ color: "white" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modulesJsonData.map((indv: any, indxx: number) => {
              return (
                <TableRow>
                  <TableCell>{indv.name}</TableCell>
                  <TableCell>{indv.status}</TableCell>
                  <TableCell>{indv.exp_return}</TableCell>
                  <TableCell>{indv.exp_return}</TableCell>
                  <TableCell>{indv.after_tax}</TableCell>
                  <TableCell>{indv.last_saved}</TableCell>
                  <TableCell>
                    <Button
                      sx={{ color: "black" }}
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        padding: "5px",
                      }}
                      className="table-buttons"
                      variant="outlined"
                      onClick={handleClose} component={Link} to="/moduleEdit"
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ color: "black" }}
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        padding: "5px",
                      }}
                      className="table-buttons"
                      variant="outlined"
                      href=""
                      onClick={moduleDisplayOpen}
                    >
                      Display
                    </Button>
                    <Button
                      sx={{ color: "black" }}
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        padding: "5px",
                      }}
                      className="table-buttons"
                      variant="outlined"
                      href=""
                    >
                      Tilting
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <h3>Module Browser</h3>
          <Grid container spacing={4} style={{ marginTop: "0px" }}>
            <Grid item xs={4} className="wrapGrids" spacing={1}>
              <div className="form-check">
                <input
                  className="module-checkbox"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label htmlFor="flexCheckDefault">Module Expected Return</label>
              </div>
              <div className="form-check">
                <input
                  className="module-checkbox"
                  type="checkbox"
                  id="flexCheckDefault1"
                />
                <label htmlFor="flexCheckDefault1">Lorem Ipsum</label>
              </div>
              <div className="form-check">
                <input
                  className="module-checkbox"
                  type="checkbox"
                  id="flexCheckDefault2"
                />
                <label htmlFor="flexCheckDefault2">Dolor Sit Amet</label>
              </div>
            </Grid>
            <Grid item xs={8} className="wrapGrids" spacing={1}>
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                <div className="modulechartSection">
                  <ScatterChart
                    width={500}
                    height={250}
                    margin={{
                      top: 0,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <XAxis
                      type="number"
                      dataKey="x"
                      name="Volatility"
                      label={{
                        value: "Volatility (%)",
                        dy: 20,
                        position: "insideBottom",
                      }}
                    />
                    <YAxis
                      type="number"
                      dataKey="y"
                      name="Exp. Return"
                      label={{
                        value: "Exp. Return (%)",
                        angle: -90,
                        position: "Left",
                      }}
                    />
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                    <Scatter
                      name="A school"
                      data={chartData}
                      fill="#585858"
                      shape="square"
                    />
                  </ScatterChart>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ border: "none" }}>
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "grey" }}>
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Name</TableCell>
                      <TableCell style={{ color: "white" }}>
                        Exp. Return
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Volatility
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Tags</TableCell>
                      <TableCell style={{ color: "white" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {graphGeneraInfoData.map((indv: any, indxx: number) => {
                      return (
                        <TableRow>
                          <TableCell>{indv.name}</TableCell>
                          <TableCell>{indv.exp_return}</TableCell>
                          <TableCell>{indv.volatility}</TableCell>
                          <TableCell>{indv.tags}</TableCell>
                          <TableCell>
                            <Button
                              sx={{ color: "black" }}
                              style={{
                                paddingLeft: "25px",
                                paddingRight: "25px",
                              }}
                              className="table-buttons"
                              variant="outlined"
                              href="../moduleEdit"
                            >
                              Select
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
