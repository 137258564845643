import { TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";

export const CorrTableBody = ({ array, setArray }: any) => {
  const [editCorrValue, setCorrEditValue] = useState("");
  const [showCorrEditIcon, setShowCorrEditIcon] = useState("");
  const [editCorrField, setCorrEditField] = useState("");

  return (
    <TableBody>
      {array &&
        array.length > 0 &&
        array.map((row: any, indx: number) => {
          if (indx >= 2)
            return (
              <TableRow key={indx}>
                <TableCell>{indx}</TableCell>
                {row.map((indv: any, indxx: number) => {
                  return (
                    indxx >= 1 && (
                      <TableCell
                        style={
                          indv === 1
                            ? { backgroundColor: "#FFD480" }
                            : {} && indxx < indx
                            ? { backgroundColor: "#FCD298" }
                            : {}
                        }
                        onClick={() => {
                          setCorrEditField(indx + "-" + indxx);
                          setCorrEditValue(indv);
                        }}
                        onMouseOver={() =>
                          setShowCorrEditIcon(indx + "-" + indxx)
                        }
                        onMouseLeave={() => setShowCorrEditIcon("")}
                        id={indx + "-" + indxx}
                      >
                        {/* {Number(indv).toFixed(2)} */}
                        {editCorrField === indx + "-" + indxx &&
                        editCorrValue ? (
                          <ReturnInputField
                            id={indx + "-" + indxx}
                            initialValue={editCorrValue}
                            returnChange={(changedValue) =>
                              setCorrEditValue(changedValue)
                            }
                            getEventValueAndResetEditField={(
                              bluredValue: string
                            ) => {
                              const tempCorrJsonData = [...array];
                              tempCorrJsonData[indx][indxx] = bluredValue;
                              setArray(tempCorrJsonData);
                              setCorrEditField("");
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {Number(indv).toFixed(2)}
                            {showCorrEditIcon === indx + "-" + indxx && (
                              <div className="editIcon">
                                <EditIcon
                                  onClick={() => {
                                    setCorrEditField(indx + "-" + indxx);
                                    setCorrEditValue(indv);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            );
        })}
    </TableBody>
  );
};
