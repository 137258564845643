import React, { useMemo, CSSProperties, useState, useEffect } from "react";
import { useGetList } from "react-admin";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
} from "@mui/material";
import { subDays, startOfDay } from "date-fns";

import Welcome from "./Welcome";
import MonthlyRevenue from "./MonthlyRevenue";
import NbNewOrders from "./NbNewOrders";
import PendingOrders from "./PendingOrders";
import PendingReviews from "./PendingReviews";
import NewCustomers from "./NewCustomers";
import OrderChart from "./OrderChart";
import ListItemButton from "@mui/material/ListItemButton";
import { Order } from "../types";
import DetailList from "./DetailList";
import InteractiveList from "../components/ListComponent";
import ListItemText from "@mui/material/ListItemText";
import {
  DB_GROUP,
  getAssetDbByDbGroupId,
  getClientsByAssetDb,
} from "../utils/constants";
import "./guide.css";
import { useParams } from "react-router";
import { DoFetch } from "../service/fetchConfig";
// import DatabaseGroups from './DatabaseGroups';
// import AssetDatabases from './AssetDatabases';
// import Clients from './Clients';

interface OrderStats {
  revenue: number;
  nbNewOrders: number;
  pendingOrders: Order[];
}

interface State {
  nbNewOrders?: number;
  pendingOrders?: Order[];
  recentOrders?: Order[];
  revenue?: string;
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {

  // useEffect(() => {
  //   GetDatabaseGroups();
  // }, [])

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [detailId, setDetailId] = useState<number>(0);
  const [databaseGroupList, setDatabaseGroupList] = useState<
    {
      name: string;
      id: number;
      db_group_id?: number;
    }[]
  >([]);
  const [assetDbList, setAssetDbList] = useState<
    {
      name: string;
      id: number;
      db_group_id?: number;
    }[]
  >([]);
  const [assetDbClientList, setAssetDbClientList] = useState<
    {
      name: string;
      id: number;
      db_group_id?: number;
    }[]
  >([]);

  const [selectedItemL1, setSelectedItemL1] = React.useState("");
  const [selectedItemL2, setSelectedItemL2] = React.useState("");
  const [selectedItemL3, setSelectedItemL3] = React.useState("");

  const [clientList, setClientList] = useState<
    {
      name: string;
      id: number;
      asset_db_id: number;
      currency: string;
      ca: string;
      children: {
        name: string;
        id: number;
        currency: string;
        ca: string;
      }[];
    }[]
  >([]);

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

  const { data: orders } = useGetList<Order>("commands", {
    filter: { date_gte: aMonthAgo.toISOString() },
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 50 },
  });

  const aggregation = useMemo<State>(() => {
    if (!orders) return {};
    const aggregations = orders
      .filter((order) => order.status !== "cancelled")
      .reduce(
        (stats: OrderStats, order) => {
          if (order.status !== "cancelled") {
            stats.revenue += order.total;
            stats.nbNewOrders++;
          }
          if (order.status === "ordered") {
            stats.pendingOrders.push(order);
          }
          return stats;
        },
        {
          revenue: 0,
          nbNewOrders: 0,
          pendingOrders: [],
        }
      );
    return {
      recentOrders: orders,
      revenue: aggregations.revenue.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      nbNewOrders: aggregations.nbNewOrders,
      pendingOrders: aggregations.pendingOrders,
    };
  }, [orders]);

  const { nbNewOrders, pendingOrders, revenue, recentOrders } = aggregation;

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  // const GetDatabaseGroups = () => {
  //   DoFetch({
  //     url: "/AssetDatabaseGroupController/GetAssetDatabaseGroup",
  //     anonymous: false,
  //     method: "POST",
  //     body: JSON.stringify({
  //       organisation_id: 1,
  //     }),
  //   }).then((response) => {
  //     console.log(response);
  //     if (response.data) {
  //       setDatabaseGroupList(response.data);
  //     }
  //   });
  // };

  // const GetAssetDatabaseByAssetDatabaseGroup = (id: any) => {
  //   DoFetch({
  //     url: "/assetdatabasecontroller/GetAssetDatabaseByAssetDatabaseGroup",
  //     anonymous: false,
  //     method: "POST",
  //     body: JSON.stringify({
  //       assetdatabase_group_id: id,
  //       // assetdatabase_group_id: "83e67d7c-20ee-40ea-b4cf-11b7cafe083c",
  //     }),
  //   }).then((response) => {
  //     console.log(response);
  //     if (response.data) {
  //       setAssetDbList(response.data);
  //     }
  //   });
  // };

 
  // const GetAssetClientsByAssetDatabase = (id: any) => {
  //   DoFetch({
  //     url: "/clientcontroller/GetClientByAssetDatabase",
  //     anonymous: false,
  //     method: "POST",
  //     body: JSON.stringify({
  //       assetdatabase_id: id,
  //       // assetdatabase_id: "8b05baaa-de38-4fc5-9aac-41d56799b399",
  //     }),
  //   }).then((response) => {
  //     console.log(response);
  //     if (response.data) {
  //       setAssetDbClientList(response.data);
  //     }
  //   });
  // };

  /**
   * GetSelectedClient
   */
  // const GetSelectedClient = (id: any) => {
  //   localStorage.setItem('selectedDBAssetClientId', id);
  //   window.location.href = '/goalEditor'
  // }

  return (
  // return isXSmall ? (
  //   <div>
  //     <div style={styles.flexColumn as CSSProperties}>
  //       {/* <Welcome /> */}
  //       <MonthlyRevenue value={revenue} />
  //       <VerticalSpacer />
  //       <NbNewOrders value={nbNewOrders} />
  //       <VerticalSpacer />
  //       {/* <PendingOrders orders={pendingOrders} /> */}
  //     </div>
  //   </div>
  // ) : isSmall ? (
  //   <div style={styles.flexColumn as CSSProperties}>
  //     <div style={styles.singleCol}>{/* <Welcome /> */}</div>
  //     <div style={styles.flex}>
  //       <MonthlyRevenue value={revenue} />
  //       <Spacer />
  //       <NbNewOrders value={nbNewOrders} />
  //     </div>
  //     <div style={styles.singleCol}>
  //       <OrderChart orders={recentOrders} />
  //     </div>
  //     <div style={styles.singleCol}>
  //       {/* <PendingOrders orders={pendingOrders} /> */}
  //     </div>
  //   </div>
  // ) : (
    <>
      <Welcome />

      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ marginTop: "10px",width:"107%" }}>
              <Grid item xs={12} md={4} className="wrapGrids" spacing={3}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Database Groups
                </Typography>

                <List dense={false} sx={{ml:-2}}>
                  {/*{databaseGroupList.map((indv, indx) => (*/}
                     {DB_GROUP.map((indv, indx) => (
                    <InteractiveList
                      key={indx}
                      item={indv}
                      selectedItem={selectedItemL1}
                      getChildList={(id) => {
                        setSelectedItemL1(String(indv.id));
                        setSelectedItemL2("");
                        setSelectedItemL3("");
                         const childArr = getAssetDbByDbGroupId(id);
                         setAssetDbList(childArr);
                        {/*GetAssetDatabaseByAssetDatabaseGroup(indv.id);*/}
                        setClientList([]);
                      }}
                    />
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={4} className="wrapGrids">
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Assets Databases
                </Typography>
                <List dense={false} sx={{ml:-2}}>
                  {assetDbList.length > 0 ? (
                    assetDbList.map((indv, indx) => (
                      <InteractiveList
                        key={indx}
                        item={indv}
                        selectedItem={selectedItemL2}
                        getChildList={(id) => {
                          setSelectedItemL2(String(id));
                          const childArr = getClientsByAssetDb(id);
                          setClientList(childArr);
                          {/*GetAssetClientsByAssetDatabase(indv.id);*/}
                        }}
                      />
                    ))
                  ) : (
                    <div style={{marginLeft:"20px"}}>Please select any database group</div>
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={4} className="wrapGrids">
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Clients
                </Typography>
                <List dense={false} sx={{ml:-2}}>
                  {clientList.length > 0 ? (
                    clientList.map((indv, indx) => (
                      <InteractiveList
                        key={indx}
                        selectedItem={selectedItemL3}
                        item={indv}
                        getChildList={(id) => {
                          setSelectedItemL3(String(id));
                          {/*GetSelectedClient(indv.id);*/}
                        }}
                      />
                    ))
                  ) : (
                    <div style={{marginLeft:"20px"}}>Please select any asset database</div>
                  )}
                </List>
              </Grid>
            </Grid>
          </Box>
          {/* <div style={styles.flexColumn}> */}
          {/* <PendingOrders
              orders={pendingOrders}
              title="card 1"
              onShowDetails={(id: number) => {
                console.log(" dashboard - id", id);

                setDetailId(id);
              }}
            />
            <DetailList id={detailId} /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
function setPostId(id: any): any {
  throw new Error("Function not implemented.");
}
