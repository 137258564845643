import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
} from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useTranslate } from "react-admin";
import Mainmenu from "../components/Mainmenu";
import publishArticleImage from "./welcome_illustration.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import CardContent from "@mui/material/CardContent";
import { FormControl, FormLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const Wealth = () => {
  const translate = useTranslate();
  const [drop, setDrop] = React.useState("");

  const dropHandleChange = (event: SelectChangeEvent) => {
    setDrop(event.target.value as string);
  };
  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
              Wealth
            </Typography>




<Grid sx={{ ml: 2, mr: 2 ,mt:-1}}>
              <List dense={false}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form>
                      <label>Available Wealth</label>
                      <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  className="txtbox"
                  variant="filled"
                />
                    </Form>
                  </Grid>

                  <Grid item xs={6}>
                    <Form>
                      <label>Initial Capital</label>
                       <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                  className="txtbox"
                  variant="filled"
                />
                    </Form>
                  </Grid>

                  
                  <Grid item xs={6} sx={{mt:-2}}>
                    <label>Amount</label>
                    <FormControl>

                  <Select
                    style={{height: "38px",width:"195px"}}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={drop}
                    onChange={dropHandleChange}
                    
                  >
                    <MenuItem value="">
                      
                    </MenuItem>
                    <MenuItem value={10}>value 1</MenuItem>
                    <MenuItem value={20}>value 2</MenuItem>
                    <MenuItem value={30}>value 3</MenuItem>
                  </Select>
                </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{mt:-2}}>
                    <Form>
                      <label>Balance</label>
                        <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue=""
                 style={{marginTop:"6px"}}
                  variant="filled"
                />
                    </Form>
                  </Grid>
                
                </Grid>
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Wealth;
