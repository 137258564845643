import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      monthly_revenue: "Monthly Revenue",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      all_reviews: "See all reviews",
      new_customers: "Database Groups",
      asset_databases: "Asset Databases",
      clients_details: "Clients",
      all_customers: "See all customers",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "PortNox  a01 r01  |  Open Asset Database",
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        agar_button: "Agarwal Extended Family",
        emma_button: "Emma Peel",
        rohit_button: "Rohit Agarwal",
        kazmanian_button: "Kazmanian Family",
      },
    },

    tables: {
      buttons: {
        new_constraints_btn: "Add New Constraints",
        estimate_frontier_btn: "Estimate Frontier Limit",
        optimize_portfolio_btn: "Optimize Portfolio",
        choose_from_efficient_frontier_btn: "Choose From Efficient Frontier",
        sensitivity_analyses_btn: "Sensitivity Analysis",
        save_module_btn: "Save Module",
        save_as_new_module_btn: "Save as New Module",
        export_to_excel_btn: "Export to Excel",
        show_ranking_btn: "Show Ranking",
        close_btn: "Close",
      },
    },

    globalData: {
      name: "Global Data",
      month_history: "30 Day Revenue History",
      header: {
        title: "PortNox  a01 r01  |  Global Data",
      },
      buttons: {
        new_constraints_btn: "Add New Constraints",
        create_and_proceed: "Create and Proceed",
        save_as_new_module_btn: "Save as New Module",
        import_from_excel_btn: "Import from Excel",
        export_to_excel_btn: "Export to Excel",
        close_btn: "Close",
        submit: "Submit",
      },
    },
    modelBuilder: {
      name: "Module Builder",
      month_history: "30 Day Revenue History",
      header: {
        title: "PortNox  a01 r01  |  Module 23 > Module Builder",
      },
      buttons: {
        estimate_button: "Estimate Frontier Limits",
        optimize_button: "Optimize Portfolio",
        choose_button: "Choose From Efficient Frontier",
        sensitivity_button: "Sensitivity Analysis",
        export_button: "Export To Excel",
        ranking_button: "Show Ranking",
        saveas_button: "Save as…",
        save_button: "Save",
      },
    },
    menu: {
      sales: "Sales",
      catalog: "Catalog",
      customers: "Customers",
    },
  },
  resources: {
    customers: {
      name: "Customer |||| Customers",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
        stateAbbr: "State",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has ordered",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    commands: {
      name: "tables",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      fields: {
        basket: {
          delivery: "Delivery",
          reference: "Reference",
          quantity: "Quantity",
          sum: "Sum",
          tax_rate: "Tax Rate",
          taxes: "Tax",
          total: "Total",
          unit_price: "Unit Price",
        },
        address: "Address",
        customer_id: "Customer",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        nb_items: "Nb Items",
        total_gte: "Min amount",
        status: "Status",
        returned: "Returned",
      },
      section: {
        order: "Order",
        customer: "Customer",
        shipping_address: "Shipping Address",
        items: "Items",
        total: "Totals",
      },
    },
    dbgroup: {
      name: "Create Asset Database Group",
    },
    datafile: {
      name: "Datafile Backup",
    },
    invoices: {
      name: "Invoice |||| Invoices",
      fields: {
        date: "Invoice date",
        customer_id: "Customer",
        command_id: "Order",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        total_gte: "Min amount",
        address: "Address",
      },
    },
    goalEditor: {
      name: "goal-editor",
      month_history: "30 Day Revenue History",
      header: {
        title: "PortNox  a01 r01  |  Agarwal Family > Goal Editor",
      },
      fields: {
        date: "Invoice date",
        customer_id: "Customer",
        command_id: "Order",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        total_gte: "Min amount",
        address: "Address",
      },
    },
    modelBuilder: {
      name: "module-builder",
      month_history: "30 Day Revenue History",
      header: {
        title: "PortNox  a01 r01  |  Module 23 > Module Builder",
      },
      buttons: {
        estimate_button: "Estimate Frontier Limits",
        optimize_button: "Optimize Portfolio",
        choose_button: "Choose From Efficient Frontier",
        sensitivity_button: "Sensitivity Analysis",
        export_button: "Export To Excel",
        ranking_button: "Show Ranking",
        saveas_button: "Save as…",
        save_button: "Save",
      },
    },

    products: {
      name: "Poster |||| Posters",
      fields: {
        category_id: "Category",
        height_gte: "Min height",
        height_lte: "Max height",
        height: "Height",
        image: "Image",
        price: "Price",
        reference: "Reference",
        sales: "Sales",
        stock_lte: "Low Stock",
        stock: "Stock",
        thumbnail: "Thumbnail",
        width_gte: "Min width",
        width_lte: "Max width",
        width: "Width",
      },
      tabs: {
        image: "Image",
        details: "Details",
        description: "Description",
        reviews: "Reviews",
      },
      filters: {
        categories: "Categories",
        stock: "Stock",
        no_stock: "Out of stock",
        low_stock: "1 - 9 items",
        average_stock: "10 - 49 items",
        enough_stock: "50 items & more",
        sales: "Sales",
        best_sellers: "Best sellers",
        average_sellers: "Average",
        low_sellers: "Low",
        never_sold: "Never sold",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fields: {
        products: "Products",
      },
    },
    reviews: {
      name: "Review |||| Reviews",
      amount: "1 review |||| %{smart_count} reviews",
      relative_to_poster: "Review on poster",
      detail: "Review detail",
      fields: {
        customer_id: "Customer",
        command_id: "Order",
        product_id: "Product",
        date_gte: "Posted since",
        date_lte: "Posted before",
        date: "Date",
        comment: "Comment",
        rating: "Rating",
      },
      action: {
        accept: "Accept",
        reject: "Reject",
      },
      notification: {
        approved_success: "Review approved",
        approved_error: "Error: Review not approved",
        rejected_success: "Review rejected",
        rejected_error: "Error: Review not rejected",
      },
    },
    segments: {
      name: "Segment |||| Segments",
      fields: {
        customers: "Customers",
        name: "Name",
      },
      data: {
        compulsive: "Compulsive",
        collector: "Collector",
        ordered_once: "Ordered once",
        regular: "Regular",
        returns: "Returns",
        reviewer: "Reviewer",
      },
    },
  },
};

export default customEnglishMessages;
