import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
} from "@mui/material";

import { useTranslate } from "react-admin";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


const Firmtable = () => {
  const translate = useTranslate();

  const firmtable = [
    {
      id: 1,
      name: "Cash",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 2,
      name: "Lower Half Volatilitye Spectrum",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 3,
      name: "Equity Risk Spectrum",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 4,
      name: "Real Assets",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 5,
      name: "Illiquid Assets",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              sx={{ ml: 8 }}
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
     Firm Wide Constraints
            </Typography>
            <Grid sx={{ ml: 1, mr: 1 }}>
              <List dense={false}>
                <TableContainer component={Paper} style={{ border: "none" }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow style={{ color: "white" }}>
                        <TableCell style={{ color: "white" }}>Name</TableCell>
                        <TableCell style={{ color: "white" }}>
                        Min Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                        Max Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                        Weight Based on
                        </TableCell>
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {firmtable.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.name}</TableCell>
                           
                            <TableCell>{indv.minWeight}</TableCell>
                            <TableCell>{indv.maxWeight}</TableCell>
                            <TableCell>{indv.weightBasedon}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>



      
    </>
  );
};

export default Firmtable;
