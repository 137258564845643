import React from "react";
import Generalinformation from "./Generalinformation";
import Topbar from "./Topbar";
// import "./goalEditor.css";
// import Matching from "./Matching";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Objective from "./Objective";
import Efficient from "./Efficient";
import Optimised from "./Optimised";
import Additional from "./Additional";
import Budget from "./Budgetconstraintstable";
import Firmtable from "./Firmtable";
import Optimalweights from "./Optimalweights";
import Buttons from "./Buttons";
// import Wealth from "./Wealth";
// import Cashflow from "./Cashflow";
// import Tables from "./Tables";

const ModuleEdit = () => {
  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex", flexDirection: "column" },
    leftCol: { flex: 1, marginRight: "0.5em" },
    rightCol: { flex: 1, marginLeft: "0.5em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };

  return (
    <>
    <Topbar />
    <Buttons />

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Generalinformation />

        </Grid>
        <Grid item xs={7}>

          <Additional />

        </Grid>
        <Grid item xs={5}>
          <Objective />

        </Grid>
        <Grid item xs={6}>


          <Budget />
          <Firmtable />
        </Grid>

        <Grid item xs={6}>

          <Efficient />
          <Optimised />
          <Optimalweights />


        </Grid>



      </Grid>

    </Box>
      
    </>
  );
};

export default ModuleEdit;
