import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { BrowserRouter, Route, RouteProps } from "react-router-dom";

import authProvider from "./authProvider";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import englishMessages from "./i18n/en";
import { lightTheme } from "./layout/themes";

import visitors from "./visitors";
import orders from "./globalData";
import products from "./products";
import invoices from "./invoices";
import GoalEditor from "./goalEditor/GoalEditor";
// import ModulesEdit from "./tables/ModulesEdit";
import ModulesEdit from "./moduleEdit/ModulesEdit";
import categories from "./categories";
import reviews from "./reviews";
import dataProviderFactory from "./dataProvider";
import Configuration from "./configuration/Configuration";
import Segments from "./segments/Segments";
import * as historyConfig from "history";
import { TabbedDatagrid } from "./globalData/TabbedDataGrid";
import ModuleBuilder from "./moduleBuilder/ModuleBuilder";


const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

export const history = historyConfig.createBrowserHistory();
const App = () => {
  return (
    <BrowserRouter>
      <Admin
        title=""
        dataProvider={undefined}
        history={history}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        disableTelemetry
        theme={lightTheme}
      >
        <CustomRoutes>
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/segments" element={<Segments />} />
          <Route path="/orderList/:tab" element={<TabbedDatagrid />} />
          <Route path="/goalEditor" element={<GoalEditor />} />
          {/*<Route path="/tables" element={<ModulesEdit />} />*/}
          <Route path="/moduleEdit" element={<ModulesEdit />} />

          <Route path="/moduleBuilder" element={<ModuleBuilder />} />
        </CustomRoutes>
        <Resource name="customers" {...visitors} />
        <Resource name="commands" {...orders} options={{ label: "Orders" }} />
        <Resource name="invoices" {...invoices} />

     
        <Resource name="products" {...products} />
        <Resource name="categories" {...categories} />
        <Resource name="remarks" {...reviews} />
      </Admin>
    </BrowserRouter>
  );
};

export default App;
