import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";
import "./moduleEdit.css";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Generalinformation = () => {
  const [horizon, setHorizon] = React.useState("");
  const [probability, setProbability] = React.useState("");
  const [escrow, setEscrow] = React.useState("");

  const [value, setValue] = React.useState("");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const horizonHandleChange = (event: SelectChangeEvent) => {
    setHorizon(event.target.value);
  };
  const probabilityHandleChange = (event: SelectChangeEvent) => {
    setProbability(event.target.value);
  };
  const escrowHandleChange = (event: SelectChangeEvent) => {
    setEscrow(event.target.value);
  };

  return (
    <>
      <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
              General Information
            </Typography>
            <Grid sx={{ ml: 3, mr: 3, mt: -2 }}>
              <List dense={false}>
                <Grid container spacing={4}>
                  <Grid item xs={2}>
                    <FormControl>
                      <label>Module Name</label>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        defaultValue=""
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl>
                      <label>Category Seperator</label>
                      <TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        defaultValue=""
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl>
                      <label >
                        Horizon for MR and EMD
                      </label>
                      <Select
                       className="horizon-txtbox"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={horizon}
                        onChange={horizonHandleChange}
                       
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>value 1</MenuItem>
                        <MenuItem value={20}>value 2</MenuItem>
                        <MenuItem value={30}>value 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl>
                      <label>Probability of Success</label>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={probability}
                        onChange={probabilityHandleChange}
                        className="horizon-txtbox"
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>value 1</MenuItem>
                        <MenuItem value={20}>value 2</MenuItem>
                        <MenuItem value={30}>value 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl>
                      <label>Escrow Asset</label>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={escrow}
                        onChange={escrowHandleChange}
                        className="horizon-txtbox"
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={10}>value 1</MenuItem>
                        <MenuItem value={20}>value 2</MenuItem>
                        <MenuItem value={30}>value 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl sx={{ mt: -1 }} variant="standard">
                      <RadioGroup
                        aria-labelledby="demo-error-radios"
                        name="quiz"
                        value={value}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="best"
                          control={<Radio />}
                          label="After Tax"
                        />
                        <FormControlLabel
                          value="worst"
                          control={<Radio />}
                          label="Before Tax"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Generalinformation;

//     <Card style={{ marginTop: "10px" }}>
//       <Box display="flex">
//         <Box flex="1">
//           <Typography
//             sx={{ ml: 2 }}
//             variant="h6"
//             component="h2"
//             gutterBottom
//             style={{ padding: "10px 10px" }}
//           >
//             General Information
//             </Typography>

//  <Grid sx={{ ml: 3, mr: 3 }}>
//               <List dense={false}>
//                 <Grid container spacing={2}>

//                   <Grid item xs={2}>

//                    <Form>
//                     <label >Module Name</label>
//                 <TextField
//               sx={{width:"70%"}}
//                   hiddenLabel
//                   id="filled-hidden-label-small"

//                   variant="filled"
//                   size="small"
//                   label=""
//                 />
//                    </Form>
//                   </Grid>

//                   <Grid item xs={2}>
//                   <Form>
//                    <label className="catlabel" >Categories Seperator</label>

//                 <TextField
//                   hiddenLabel

//  sx={{marginLeft:"-61%",mt:3,width:"150px"}}
//                   variant="filled"

//                   label=""

//                 />
//                    </Form>
//                   </Grid>
//                   <Grid item xs={2}>
//                   <Form>
//                   <label className="horlabel" >Horizon for MR and EMD</label>
//                 <FormControl size="small" >
//                   <Select
//                     labelId="demo-select-small"
//                     id="demo-select-small"
//                     value={age}
//                     label="Age"
//                     onChange={handleChange}
//                    sx={{marginLeft:"-115%",mt:1,width:"150px"}}

//                   >
//                     <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={10}>Ten</MenuItem>
//                     <MenuItem value={20}>Twenty</MenuItem>
//                     <MenuItem value={30}>Thirty</MenuItem>
//                   </Select>
//                 </FormControl>

//                    </Form>
//                   </Grid>

//  <Grid item xs={2}>
//                   <Form>
//                     <label className="problabel">Probability of Success</label>
//                 <FormControl size="small" >
//                   <Select
//                     labelId="demo-select-small"
//                     id="demo-select-small"
//                     value={age}
//                     label="95%"
//                     onChange={handleChange}
//                   sx={{marginLeft:"-110%",mt:1,width:"150px"}}

//                   >
//                     <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={10}>95.00%</MenuItem>
//                     <MenuItem value={20}>value 2</MenuItem>

//                   </Select>
//                 </FormControl>
//                    </Form>
//                   </Grid>

//                    <Grid item xs={2}>
//                   <Form>
//                   <label className="escrowlabel">Escrow Asset</label>
//                 <FormControl size="small" >
//                   <Select
//                     labelId="demo-select-small"
//                     id="demo-select-small"
//                     value={age}
//                     label="95%"
//                     onChange={handleChange}

//                      sx={{marginLeft:"2%",mt:-3,width:"150px"}}

//                   >
//                     <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={10}>cash</MenuItem>
//                     <MenuItem value={20}>value 2</MenuItem>

//                   </Select>
//                 </FormControl>
//                    </Form>
//                   </Grid>

//                    <Grid item xs={2}>
//                   <Form>
//                   <RadioButtonGroupInput
//               sx={{marginLeft:"-20px",mt:-1}}

//                   source=""
//                   className="radio-btn"
//                   choices={[
//                     { id: "beforetax", name: "Before Tax" },
//                     { id: "aftertax", name: "After Tax" },
//                   ]}
//                 />

//                    </Form>
//                   </Grid>

//                 </Grid>

//               </List>
//             </Grid>

//         </Box>
//       </Box>
//     </Card>
