import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Objective = () => {


  const [objective, setObjective] = React.useState("");
  const [btndisabled, setbtnDisabled] = React.useState(false);

  const objectiveHandleChange = (event: SelectChangeEvent) => {
    setObjective(event.target.value as string);
  };
  const handleDisable = () => {
    setbtnDisabled(true);
  };
  const handleEnable = () => {
    setbtnDisabled(false);
  };






  return (
    <>
    <Card style={{ marginTop: "2px" }}>
      <Box display="flex">
        <Box flex="1">
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            style={{ padding: "10px 10px" }}
          >

          </Typography>
          <Grid sx={{ ml: 3, mr: 3, mt: 3 }}>
            <List dense={false}>
              <Grid container spacing={2}>





                <Grid item xs={8} sx={{ mt: 1 }}>
                  <label>Objective</label>
                  <FormControl fullWidth>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={objective}

                      label="Age"
                      onChange={objectiveHandleChange}
                      style={{height: "38px"}}
                    >
                      <MenuItem value={1} onClick={handleEnable}>Maximize Expected Return by Setting Volatility</MenuItem>
                      <MenuItem value={2} onClick={handleEnable}>Minimize Volatility By Setting Expected Return</MenuItem>
                      <MenuItem value={3} onClick={handleEnable}> Setting Minimum Return To</MenuItem>
                      <MenuItem value={4} onClick={handleDisable}>Maximize Minimum Return</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={4}>
                  <label>Amount</label>
                  <FormControl fullWidth>

                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                      disabled={btndisabled}

                    />
                  </FormControl>
                </Grid>



              </Grid>


            </List>
          </Grid>
        </Box>
      </Box>
    </Card>


    </>
  );
};

export default Objective;
