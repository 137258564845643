import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
} from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useTranslate } from "react-admin";
import Mainmenu from "../components/Mainmenu";
import publishArticleImage from "./welcome_illustration.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import CardContent from "@mui/material/CardContent";
import { FormControl, FormLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { CenterFocusWeakTwoTone } from "@mui/icons-material";

const Matching = () => {
  const [category, setCategory] = React.useState("");
  const translate = useTranslate();

  const categoryHandleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
              Matching
            </Typography>
            <Grid sx={{ ml: 2, mr: 2 }}>
              <List dense={false}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Card
                    className="match-card"
                      style={{ backgroundColor: "gray" }}
                    
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14, textAlign: "center" }}
                          color="text.secondary"
                          gutterBottom
                          style={{ color: "white" }}
                        >
                          Module
                        </Typography>

                        <Typography
                          sx={{ textAlign: "center", fontSize: 17 }}
                          variant="h6"
                          component="h2"
                          gutterBottom
                          style={{ color: "white" }}
                          // style={{ padding: "20px 20px" }}
                        >
                          Module 25
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4} >
                    <Card
                    
                      className="match-card"
                      style={{ backgroundColor: "gray" }}
                     
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14, textAlign: "center" }}
                          color="text.secondary"
                          gutterBottom
                          style={{ color: "white" }}
                        >
                          Expected Return
                        </Typography>

                        <Typography
                          sx={{ textAlign: "center",fontSize: 17 }}
                          variant="h5"
                          component="h2"
                          gutterBottom
                          style={{ color: "white" }}
                        >
                          9.69%
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card
                      className="match-card"
                      style={{ backgroundColor: "gray" }}
                
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14, textAlign: "center" }}
                          color="text.secondary"
                          gutterBottom
                          style={{ color: "white" }}
                        >
                          Volatility
                        </Typography>

                        <Typography
                          sx={{ textAlign: "center" ,fontSize: 18}}
                          variant="h5"
                          component="h2"
                          gutterBottom
                          style={{ color: "white" }}
                        >
                          10.90%
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                
                <Grid>
                  <Grid container spacing={2} style={{padding:"25px",marginLeft: "1%"}} sx={{ml:1}}>
                    <FormControl className="match-radio" >
                      <RadioGroup
                     
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="beforetax"
                            control={<Radio />}
                            label="Automatically match the goal to the module with the lower funding cost"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="aftertax"
                            control={<Radio />}
                            label="Automatically match the goal to the module with the lower funding cost"
                          />
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                 </Grid>
<Grid>
                <Grid container spacing={2}>
                  <Grid item xs={5} sx={{ml:3}} style={{marginTop:"-18px"}}>
                   <label>within the following category</label>
               <FormControl>

                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={category}
                    onChange={categoryHandleChange}
                    className="cat-txtbox"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                     <MenuItem value={10}>value 1</MenuItem>
                    <MenuItem value={20}>value 2</MenuItem>
                    <MenuItem value={30}>value 3</MenuItem>
                  </Select>
                </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{ mt:1,ml:2}}>
                    {/* <TextField
                      className="match-txtbox"
                      id="outlined-basic"
                      label="Launch Browser"
                      variant="outlined"
                    /> */}
                  <input
                          type="Button"
                          className="launchButton"
                          value="Launch Browser"
                        />
                    
                  </Grid>
                </Grid>
                </Grid>
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Matching;
