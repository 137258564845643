import React, { useState } from "react";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  FileInput,
  ImageField,
} from "react-admin";
import { TableCell, TableBody, TableRow } from "@mui/material";
import { Customer } from "../types";
import "./guide.css";
import { TabbedDatagrid } from "./TabbedDataGrid";

const OrderList = React.memo(() => {
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const file = "";
  const fileType = ["application/vnd.ms-excel"];

  return <TabbedDatagrid />;
});

export default OrderList;
