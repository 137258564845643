import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Card, CardActions, Typography, Grid, List } from "@mui/material";
import { useMediaQuery, Theme, FormControl, FormLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import {
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { TextInput, SelectInput, useTranslate } from "react-admin";
import Mainmenu from "../components/Mainmenu";
import Addnewconstraint from "./Addnewconstraint";
import Showranking from "./Showranking";
import publishArticleImage from "./welcome_illustration.svg";
import "./moduleEdit.css";
// import Subefficientfrontier from "./Subefficientfrontier";
import {
  LineChart,
  BarChart,
  Label,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Subsensitivity() {
  const chartData = [
    {
      name: 1.1,
      MER: 1.2,
    },
    {
      name: 5.02,
      MER: 6.0,
    },
    {
      name: 8.94,
      MER: 5.5,
    },
    {
      name: 12.86,
      MER: 5.04,
    },
    {
      name: 16.78,
      MER: 4.12,
    },
    {
      name: 20.7,
      MER: 3.12,
    },
  ];

  const barChartData = [
    {
      wealth: 54.05,
      frequency: 0.005,
    },
    {
      wealth: 154.05,
      frequency: 0.018,
    },
    {
      wealth: 254.05,
      frequency: 0.034,
    },
    {
      wealth: 354.05,
      frequency: 0.042,
    },
    {
      wealth: 454.05,
      frequency: 0.041,
    },
    {
      wealth: 554.05,
      frequency: 0.031,
    },
    {
      wealth: 654.05,
      frequency: 0.023,
    },
    {
      wealth: 754.05,
      frequency: 0.015,
    },
    {
      wealth: 854.05,
      frequency: 0.011,
    },
    {
      wealth: 954.05,
      frequency: 0.008,
    },
    {
      wealth: 1054.05,
      frequency: 0.004,
    },
    {
      wealth: 1154.05,
      frequency: 0.003,
    },
    {
      wealth: 1254.05,
      frequency: 0.001,
    },
    {
      wealth: 1354.05,
      frequency: 0.001,
    },
    {
      wealth: 1454.05,
      frequency: 0.001,
    },
    {
      wealth: 1554.05,
      frequency: 0.0005,
    },
    {
      wealth: 1654.05,
      frequency: 0.0005,
    },
    {
      wealth: 1754.05,
      frequency: 0.0005,
    },
  ];

  const moduleInfoData = [
    {
      id: 1,
      asset: "Cash",
      volatility: "10.90%",
      expreturn: "8.67%",
      newweight: "N/A",
      oldweight: "6.7%",
      lowerbounce: "0.00%",
      upperbounce: "100.00%",
    },
    {
      id: 2,
      asset: "Managed Futures",
      volatility: "10.90%",
      expreturn: "8.67%",
      newweight: "N/A",
      oldweight: "6.7%",
      lowerbounce: "0.00%",
      upperbounce: "100.00%",
    },
    {
      id: 3,
      asset: "Illiquid Equities(VC)",
      volatility: "10.90%",
      expreturn: "8.67%",
      newweight: "N/A",
      oldweight: "6.7%",
      lowerbounce: "0.00%",
      upperbounce: "100.00%",
    },
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const stylesens = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [sensitivityOpen, setSensitivityOpen] = React.useState(false);
  const handleSensitivityOpen = () => setSensitivityOpen(true);
  const handleSensitivityClose = () => setSensitivityOpen(false);

  const [open, setOpen] = React.useState(false);
  const [term, setTerm] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const termHandleChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };

  return (
    <div>
      <Button
        variant="outlined"
        sx={{ color: "black", mt: 2, mr: 2 }}
        onClick={handleClickOpen}
      >
        Sensitivity Analysis
      </Button>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={stylesens}>
          <h4>Module: Module 22, Exp. Return:9.69%, Volatility: 10.90%</h4>
          <Grid container spacing={4} style={{ marginTop: "30px" }}>
            <Grid item xs={12} className="wrapGrids" spacing={1}>
              {/* <SelectInput
                source="Efficient Frontier with Current Budget Constraints"
                choices={[{ id: "0", name: "Minimum Expected Return" }]}
                fullWidth
              /> */}
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                {/* <label>
                  Efficient Frontier with Current Budget Constraints
                </label> */}

                <div className="chartSection">
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow style={{ height: "50px", border: "0px" }}>
                        <TableCell>
                          <LineChart
                            width={350}
                            height={300}
                            data={chartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 0,
                              bottom: 15,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 0" />
                            <XAxis dataKey="name">
                              <Label
                                value="Investment horizon (Year)"
                                offset={1}
                                position="insideBottom"
                                dy={15}
                              />
                            </XAxis>
                            <YAxis
                              dataKey="MER"
                              label={{
                                value: "Expected Max. Drawdown (%)",
                                angle: -90,
                                position: "Left",
                              }}
                            />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Line
                              type="monotone"
                              dataKey="MER"
                              stroke="#8F8F8F"
                              strokeWidth={3}
                              activeDot={{ r: 4 }}
                            />
                          </LineChart>
                        </TableCell>

                        <TableCell>
                          <LineChart
                            width={350}
                            height={300}
                            // BorderWidth={5}
                            data={chartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 0,
                              bottom: 15,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 0" />
                            <XAxis dataKey="name">
                              <Label
                                value="Investment horizon (Year)"
                                offset={1}
                                position="insideBottom"
                                dy={15}
                              />
                            </XAxis>
                            <YAxis
                              dataKey="MER"
                              label={{
                                value: "Expected Portfolio Return (%)",
                                angle: -90,
                                position: "Left",
                              }}
                            />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Line
                              type="monotone"
                              dataKey="MER"
                              stroke="#8F8F8F"
                              strokeWidth={3}
                              activeDot={{ r: 4 }}
                            />
                          </LineChart>
                        </TableCell>

                        <TableCell>
                          <BarChart
                            width={350}
                            height={300}
                            data={barChartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 15,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="wealth"
                              label={{
                                value: "Wealth ($)",
                                position: "insideBottom",
                                dy: 15,
                              }}
                            />
                            <YAxis
                              label={{
                                value: "Frequency",
                                angle: -90,
                                position: "insideLeft",
                                dx: 0,
                              }}
                            />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="frequency" fill="#8D8D8D" />
                            {/* <Bar dataKey="uv" fill="#8884d8" /> */}
                          </BarChart>

                          {/* <BarChart
                            width={300}
                            height={300}
                            data={barChartData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 0,
                              bottom: 15,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" >
                              <Label value="Wealth ($)" offset={1} position="insideBottom" dy={15} />
                            </XAxis>
                            <YAxis dataKey="MER" label={{ value: 'Frequency', angle: -90, position: 'Left' }} />
                            <Tooltip />
                            
                            <Bar dataKey="pv" fill="#8884d8" />
                          </BarChart> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: "10px" }}>
              <TableContainer
                component={Paper}
                style={{ marginTop: "10px", border: "none" }}
              >
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "grey" }}>
                    <TableRow>
                      <TableCell style={{ color: "white" }}>
                        Asset Name
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Optimized Olatility
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Optimized Exp. Return
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        New Weight
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Old Weight
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Lower Bounce
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Upper Bounce
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {moduleInfoData.map((indv: any, indxx: number) => {
                      return (
                        <TableRow style={{ height: "50px" }}>
                          <TableCell>{indv.asset}</TableCell>
                          <TableCell>{indv.volatility}</TableCell>
                          <TableCell>{indv.expreturn}</TableCell>
                          <TableCell>{indv.newweight}</TableCell>
                          <TableCell>{indv.oldweight}</TableCell>
                          <TableCell>{indv.lowerbounce}</TableCell>
                          <TableCell>{indv.upperbounce}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {children} */}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
