import React from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export const TableTabMatrix = ({
  array,
  startIndex,
  children,
}: {
  array: any;
  startIndex: number;
  children: any;
}) => {
  return array && array.length > 0 ? (
    <TableContainer component={Paper} style={{overflow: "scroll", width: "1770px", height: "600px"}}>
      <Table aria-label="simple table">
        <TableHead style={{ backgroundColor: "grey" }}>
          <TableRow>
            {array &&
              array.length > 0 &&
              array[startIndex].map((indv: any, indx: number) => (
                <TableCell key={indx} style={{ color: "white" }}>
                  {indv}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        {children}
      </Table>
    </TableContainer>
  ) : (
    <div>Please upload a valid Excel file.</div>
  );
};
