import { TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";

export const AssetGrpTableBody = ({ array, setArray }: any) => {
  const [editAssetGrpValue, setAssetGrpEditValue] = useState("");
  const [showAssetGrpEditIcon, setShowAssetGrpEditIcon] = useState("");
  const [editAssetGrpField, setAssetGrpEditField] = useState("");

  return (
    <TableBody>
      {array &&
        array.length > 0 &&
        array.map((row: any, indx: number) => {
          if (indx >= 2)
            return (
              <TableRow key={indx}>
                  <TableCell>{indx}</TableCell>
                  {row.map((indv: any, indxx: number) => {
                    return (
                      indxx >= -1 && (
                        <TableCell
                          onClick={() => {
                            setAssetGrpEditField(indx + "-" + indxx);
                            setAssetGrpEditValue(indv);
                          }}
                          onMouseOver={() =>
                            setShowAssetGrpEditIcon(indx + "-" + indxx)
                          }
                          onMouseLeave={() => setShowAssetGrpEditIcon("")}
                          id={indx + "-" + indxx}
                        >
                          {editAssetGrpField === indx + "-" + indxx &&
                          editAssetGrpValue ? (
                            <ReturnInputField
                              id={indx + "-" + indxx}
                              initialValue={editAssetGrpValue}
                              returnChange={(changedValue) =>
                                setAssetGrpEditValue(changedValue)
                              }
                              getEventValueAndResetEditField={(
                                bluredValue: string
                              ) => {
                                const tempAssetGrpJsonData = [...array];
                                tempAssetGrpJsonData[indx][indxx] = bluredValue;
                                setArray(tempAssetGrpJsonData);
                                setAssetGrpEditField("");
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {indv}
                              {showAssetGrpEditIcon === indx + "-" + indxx && (
                                <div className="editIcon">
                                  <EditIcon
                                    onClick={() => {
                                      setAssetGrpEditField(indx + "-" + indxx);
                                      setAssetGrpEditValue(indv);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {/* {indv} */}
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
            );
        })}
    </TableBody>
  );
};
