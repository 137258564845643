import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const GeneralInformation = () => {
  const [type, setType] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [term, setTerm] = React.useState("");
  const [horizon, setHorizon] = React.useState("");
  const [Probability, setProbability] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };
  const purposeHandleChange = (event: SelectChangeEvent) => {
    setPurpose(event.target.value as string);
  };
  const termHandleChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };
  const horizonHandleChange = (event: SelectChangeEvent) => {
    setHorizon(event.target.value as string);
  };
  const probabilityHandleChange = (event: SelectChangeEvent) => {
    setProbability(event.target.value as string);
  };


  

  return (
    <>
    <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              sx={{ ml: 4 }}
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
              General Information
            </Typography>

            <div style={{ paddingRight: "10px", paddingLeft: "15px" }}>
              <Form>
                <Grid container>
                  <Grid>
                  <label className="name-label">Name</label>

                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    defaultValue=""
                    className="txtbox"
                    variant="filled"
                  />
               </Grid>
<Grid>
                  <FormControl>
                       <label className="name-label">Type</label>
                    <Select
                   style={{ width: "215px",
    marginTop: "-29px",
    height: "38px",
    marginLeft: "100px"}}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={type}
                      onChange={handleChange}
                     
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>value 1</MenuItem>
                      <MenuItem value={20}>value 2</MenuItem>
                      <MenuItem value={30}>value 3</MenuItem>
                    </Select>
                  </FormControl>
</Grid>
                  
<Grid>
                  <FormControl>
                    <label className="name-label">Purpose</label>
                    <Select
                     style={{ width: "215px",
    marginTop: "-29px",
    height: "38px",
    marginLeft: "100px"}}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={purpose}
                      onChange={purposeHandleChange}
                      
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>value 1</MenuItem>
                      <MenuItem value={20}>value 2</MenuItem>
                      <MenuItem value={30}>value 3</MenuItem>
                    </Select>
                  </FormControl>
</Grid>
<Grid>
                 
                  <FormControl>
                     <label className="name-label">Term</label>
                    <Select
                     style={{ width: "215px",
    marginTop: "-29px",
    height: "38px",
    marginLeft: "100px"}}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={term}
                      onChange={termHandleChange}
                      
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>value 1</MenuItem>
                      <MenuItem value={20}>value 2</MenuItem>
                      <MenuItem value={30}>value 3</MenuItem>
                    </Select>
                  </FormControl>
    </Grid>
    <Grid>            
                  <FormControl>
                      <label className="name-label">Horizon</label>
                    <Select
                     style={{ width: "215px",
    marginTop: "-29px",
    height: "38px",
    marginLeft: "100px"}}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={horizon}
                      onChange={horizonHandleChange}
                     
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>value 1</MenuItem>
                      <MenuItem value={20}>value 2</MenuItem>
                      <MenuItem value={30}>value 3</MenuItem>
                    </Select>
                  </FormControl>
      </Grid>   
      <Grid>       
                  <FormControl>
                      <label className="name-label">Probability</label>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={Probability}
                      onChange={probabilityHandleChange}
                      style={{ width: "215px",
    marginTop: "-29px",
    height: "38px",
    marginLeft: "100px"}}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>value 1</MenuItem>
                      <MenuItem value={20}>value 2</MenuItem>
                      <MenuItem value={30}>value 3</MenuItem>
                    </Select>
                  </FormControl>
                  </Grid>
                  <RadioButtonGroupInput
                    source=""
                    choices={[
                      { id: "beforetax", name: "Before Tax" },
                      { id: "aftertax", name: "After Tax" },
                    ]}
                  />
                </Grid>
              </Form>
            </div>
          </Box>
        </Box>
      </Card>  
    </>
  );
};

export default GeneralInformation;
