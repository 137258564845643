import "./moduleEdit.css";
import React, { useMemo, CSSProperties, useState, useEffect } from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DoFetch } from "../service/fetchConfig";

import Objective from "./Objective";


const Optimalweights = () => {

//   useEffect(()=>{
//   GetOptimalWeight();
// },[])




  // const GetOptimalWeight = () => {
  //   DoFetch({
  //     // url: "/AssetDatabaseGroupController/GetAssetDatabaseGroup",
  //     url: "/DBModuleController/GetDBModuleDetails",
  //     anonymous: false,
  //     method: "POST",
  //     body: JSON.stringify({
  //       // organisation_id: 1,
  //       "DBModuleId" : "8b5c4923-6045-4c35-8614-829e1e8fc1a1",
  //     }),
  //   }).then((response) => {
  //     console.log(response);
  //     if (response.data) {
  //       // setDatabaseGroupList(response.data);
  //     }
  //   });
  // };


    const optweight = [
        {
          assetclass: "Cash",
          weight: "0.00%",
          
        },
        {
            assetclass: "TE Cash",
            weight: "85.81%",
            
        },
        {
            assetclass: "U.S Fixed Icome Inv.Grade Cash",
            weight: "0.00%",
            
        },
        {
            assetclass: "TE IG Fixed Income",
            weight: "6.55%",
            
        },
        {
            assetclass: "U.S. Fixed Income HY",
            weight: "0.00%",
            
        },
      ];

      const optimalgroupWeights = [
        {
          assetclass: "Cash Only",
          weight: "85.81%",
          
        },
        {
            assetclass: "Lower Half Volatility Spectrum",
            weight: "13.37%",
            
        },
        {
            assetclass: "Equity Risk Spectrum",
            weight: "0.82%",
            
        },
        {
            assetclass: "Real Assets",
            weight: "0.00%",
            
        },
        {
            assetclass: "Liquid Assets",
            weight: "0.00%",
            
        },
        
      ];
    
  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "0.5em" },
    rightCol: { flex: 1, marginLeft: "0.5em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card style={{ marginTop: "10px" }}>
              <Box display="flex">
                <Box flex="1">
                  <Typography
                    sx={{ ml: 3 }}
                    variant="h6"
                    component="h2"
                    gutterBottom
                    style={{ padding: "10px 10px" }}
                  >
                    Optimal Weights
                  </Typography>

                  <Grid sx={{ ml: 1, mr: 1 }}>
              <List dense={false}>
                <TableContainer component={Paper} style={{ border: "none" }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow style={{ color: "white" }}>
                        <TableCell style={{ color: "white" }}>Asset Class</TableCell>
                        <TableCell style={{ color: "white" }}>
                     Weight
                        </TableCell>
                        
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {optweight.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.assetclass}</TableCell>
                           
                            <TableCell>{indv.weight}</TableCell>
                            
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </List>
            </Grid>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
   <Card style={{ marginTop: "10px" }}>
              <Box display="flex">
                <Box flex="1">
                  <Typography
                    sx={{ ml: 1 }}
                    variant="h6"
                    component="h2"
                    gutterBottom
                    style={{ padding: "10px 10px" }}
                  >
                    Optimal Group Weights
                  </Typography>

                  <Grid sx={{ ml: 1, mr: 1 }}>
              <List dense={false}>
                <TableContainer component={Paper} style={{ border: "none" }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow style={{ color: "white" }}>
                        <TableCell style={{ color: "white" }}>Asset Class</TableCell>
                        <TableCell style={{ color: "white" }}>
                     Weight
                        </TableCell>
                        
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {optimalgroupWeights.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.assetclass}</TableCell>
                           
                            <TableCell>{indv.weight}</TableCell>
                            
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </List>
            </Grid>
                </Box>
              </Box>
            </Card>

          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Optimalweights;
