import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Collapse, ListItemButton } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
// function generate(element) {
//   return [0, 1, 2].map((value) =>
//     React.cloneElement(element, {
//       key: value,
//     })
//   );
// }

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function InteractiveList({
  item,
  getChildList,
  selectedItem,
}: {
  item: {
    name: string;
    id: number;
    db_group_id?: number;
    asset_db_id?: number;
    currency?: string;
    ca?: string;
    children?: {
      name: string;
      id: number;
      currency: string;
      ca: string;
    }[];
  };
  getChildList: (id: any) => void | null;
  selectedItem: string;
}) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <React.Fragment>
      <ListItem
        disablePadding
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <MoreVertIcon />
          </IconButton>
        }
      >
        <ListItemButton
          selected={Number(selectedItem) === item.id}
          onClick={() => {
            getChildList(item.id);
          }}
        >
          {item.children && item.children.length > 0 && (
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={item.name} />
        </ListItemButton>
      </ListItem>
      {item.children && item.children.length > 0 && (
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.length > 0 &&
              item.children.map((indv, indx) => (
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <GroupsIcon />
                  </ListItemIcon>
                  <ListItemText primary={indv.name} />
                </ListItemButton>
              ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}
