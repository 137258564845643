import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useTranslate } from "react-admin";
import Mainmenu from "../components/Mainmenu";
import publishArticleImage from "./welcome_illustration.svg";

const Topbar = () => {
  const translate = useTranslate();

  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <Card style={{ marginTop: "20px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              style={{ padding: "20px 20px" }}
            >
              {/* {translate("pos.goalEditor.header.title")} */}
              PortNox a01 r01  |  Database wide modules
              
            </Typography>
          </Box>
          <Mainmenu />
        </Box>
      </Card>
    </>
  );
};

export default Topbar;
