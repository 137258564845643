import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
} from "@mui/material";

import { useTranslate } from "react-admin";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



const Tables = () => {
  const translate = useTranslate();

  const tableData = [
    {
      id: 1,
      time: "1",
      initialcapital: "$411,351",
      investreturn: "$4,344",
      annualspend: "$81,600",
      occassional: "$0",
      endingcapital: "$334095",
      
    },
    {
      id: 2,
      time: "2",
      initialcapital: "$334,095",
      investreturn: "$3528",
      annualspend: "$83232",
      occassional: "$0",
      endingcapital: "$254,390",
    },
    {
      id: 3,
      time: "3",
      initialcapital: "$254,390",
      investreturn: "$2686",
      annualspend: "$84,897",
      occassional: "$0",
      endingcapital: "$172,180",
    },
    {
      id: 4,
      time: "4",
      initialcapital: "$172,180",
      investreturn: "$1818",
      annualspend: "$86,595",
      occassional: "$0",
      endingcapital: "$87,404",
    },
    {
      id: 5,
      time: "5",
      initialcapital: "$07,404",
      investreturn: "$923",
      annualspend: "$00.020",
      occassional: "$0",
      endingcapital: "$0",
    },
   
  ];


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <Card style={{ marginTop: "10px" }}>
        <Box display="flex">
          <Box flex="1">
            <Grid sx={{ ml: 3, mr: 3 }}>
              <List dense={false}>
              <TableContainer component={Paper} style={{border: "none"}}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey"}}>
                      <TableRow style={{ color: "white" }}>
                      <TableCell style={{ color: "white" }}>Time</TableCell>
                        <TableCell style={{ color: "white" }}>Initial Capital</TableCell>
                        <TableCell style={{ color: "white" }}>Invest Return</TableCell>
                        <TableCell style={{ color: "white" }}>Annual Spend w/inflation</TableCell>
                        <TableCell style={{ color: "white" }}>Occasional Cash Flow</TableCell>
                        <TableCell style={{ color: "white" }}>Ending Capital</TableCell> 
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    

                  {tableData.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.time}</TableCell>
                            <TableCell>{indv.initialcapital}</TableCell>
                            <TableCell>{indv.investreturn}</TableCell>
                            <TableCell>{indv.annualspend}</TableCell>
                            <TableCell>{indv.occassional}</TableCell>
                            <TableCell>{indv.endingcapital}</TableCell>
                            
                          </TableRow>
                        );
                      })}
                    </TableBody>
                   
                  </Table>
                </TableContainer>



                  
                
                 
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Tables;
