import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  FormControl,
  FormLabel,
  Card,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Efficient = () => {
  return (
    <>
    <Card style={{ marginTop: "2px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              sx={{ ml: 8 }}
              variant="h6"
              component="h2"
              gutterBottom
              style={{ padding: "10px 10px" }}
            >
              Efficient Frontier Limits
            </Typography>

            <Grid sx={{ ml: 3, mr: 2,mt:-3 }}>
              <List dense={false}>
                <div className="rows" style={{ display: "flex" }}>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label></label>
                        <input
                          type="Button"
                          className="maxbtn"
                          value="Max"
                        />
                        <input
                          type="Button"
                          className="minbtn"
                          value="Min"
                        />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Expected Return</label>
                        <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Volatility</label>
                          <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Minimum Return</label>
                         <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Expected MD</label>
                         <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                      </Grid>
                    </Form>
                  </div>
                </div>
              </List>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Efficient;


