import * as React from "react";
import { Fragment, useCallback } from "react";
import { useState } from "react";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";
import {
  Box,
  useMediaQuery,
  Tab,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  TextField,
  Card,
  Typography,
  Grid,
  CardActions,
  Button,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Customer } from "../types";
import * as XLSX from "xlsx";
import { ReturnInputField } from "../components/ReturnInputField";
import EditIcon from "@mui/icons-material/Edit";
import "./ModuleBuilder.css";
import { useParams } from "react-router";
import { history } from "../App";
import Mainmenu from "../components/Mainmenu";
import {
  LineChart,
  Label,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ModuleBuilder = React.memo(({ match }: any) => {
  const translate = useTranslate();
  const [editField, setEditField] = React.useState("");
  const [editCorrField, setCorrEditField] = React.useState("");
  const [editAssetGrpField, setAssetGrpEditField] = React.useState("");
  const [editRangePlcyField, setRangePlcyEditField] = React.useState("");
  const [currentTab, setCurrentTab] = React.useState("asset-classes");
  const [assetsJsonDataArr, setAssetsJsonDataArr] = useState<any>([]);
  const [correlationJsonDataArr, setCorrelationJsonDataArr] = useState<any>();
  const [assetGroupJsonDataArr, setAssetGroupJsonDataArr] = useState<any>();
  const [rangePolicyJsonDataArr, setRangePolicyJsonDataArr] = useState<any>();

  const graphGeneraInfoData = [
    {
      id: 1,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 2,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 3,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 4,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 5,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 6,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
    {
      id: 7,
      asset: "",
      weight: "",
      lb: "",
      ub: "",
    },
  ];

  const capitalMarketData = [
    {
      id: 1,
      name: "Cash",
      expReturn: "1.95%",
      volatility: "1.56%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "0.00%",
    },
    {
      id: 2,
      name: "TE Cash",
      expReturn: "1.21%",
      volatility: "1.27%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "85.81%",
    },
    {
      id: 3,
      name: "U.S. Fixed Income Inv. Grade",
      expReturn: "4.03%",
      volatility: "5.63%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "0.00%",
    },
    {
      id: 4,
      name: "TE IG Fixed Income",
      expReturn: "2.53%",
      volatility: "4.48%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "6.55%",
    },
    {
      id: 5,
      name: "U.S. Fixed Income HY",
      expReturn: "7.00%",
      volatility: "13.31%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "0.00%",
    },
    {
      id: 6,
      name: "U.S. Fixed Income HY",
      expReturn: "7.00%",
      volatility: "13.31%",
      liquidity: "100.00%",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      optimalWeight: "0.91%",
    },
  ];

  const firmWideConstraintsData = [
    {
      id: 1,
      name: "Cash",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 2,
      name: "Lower Half Volatilitye Spectrum",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 3,
      name: "Equity Risk Spectrum",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 4,
      name: "Real Assets",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
    {
      id: 5,
      name: "Illiquid Assets",
      minWeight: "0.00%",
      maxWeight: "100.00%",
      weightBasedon: "Overall Module",
    },
  ];

  const chartData = [
    {
      name: 1.1,
      MER: 1.2,
    },
    {
      name: 5.02,
      MER: 6.0,
    },
    {
      name: 8.94,
      MER: 5.5,
    },
    {
      name: 12.86,
      MER: 5.04,
    },
    {
      name: 16.78,
      MER: 4.12,
    },
    {
      name: 20.7,
      MER: 3.12,
    },
  ];

  let params = useParams();

  history.listen((update) => {
    if (update.location.state) setCurrentTab(String(update.location.state));
  });
  React.useEffect(() => {
    if (params.tab) setCurrentTab(params.tab);
  }, [params]);

  const TabbedDatagrid = () => {
    return (
      <div className="App">
        <div
          className="container upper-container"
          style={{ backgroundColor: "white" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} md={4} className="wrapGrids" spacing={3}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  General Information
                </Typography>
                <div>
                  <Form className="labelForm">
                    <Grid container>
                      <label>Module Name</label>
                      <TextInput source="Module Name" fullWidth />
                      <label>Category Separator</label>
                      <TextInput source="Category Separator" fullWidth />
                      <label>Horizon for MR and EMD</label>
                      <SelectInput
                        source="Horizon for MR and EMD"
                        choices={[
                          { id: "1", name: "value 1" },
                          { id: "2", name: "value 2" },
                        ]}
                        fullWidth
                      />
                      <label>Probability of Success</label>
                      <SelectInput
                        source="Probability of Success"
                        choices={[
                          { id: "1", name: "value 1" },
                          { id: "2", name: "value 2" },
                        ]}
                        fullWidth
                      />
                      <label>Escrow Asset</label>
                      <SelectInput
                        source="Escrow Asset"
                        choices={[
                          { id: "1", name: "value 1" },
                          { id: "2", name: "value 2" },
                        ]}
                        fullWidth
                      />
                      <RadioButtonGroupInput
                        source=""
                        choices={[
                          { id: "Before Tax", name: "beforetax" },
                          { id: "After tax", name: "aftertax" },
                        ]}
                      />
                    </Grid>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="wrapGrids">
                <div style={{ marginTop: "80px" }}>
                  <Form className="labelForm">
                    <Grid container>
                      <label>
                        Efficient Frontier with Current Budget Constraints
                      </label>
                      <SelectInput
                        source="Efficient Frontier with Current Budget Constraints"
                        choices={[{ id: "0", name: "Minimum Expected Return" }]}
                        fullWidth
                      />
                      <div className="chartSection">
                        <LineChart
                          width={400}
                          height={400}
                          data={chartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 15,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 0" />
                          <XAxis dataKey="name" dy={0}>
                            <Label
                              value="Standard Deviation (%)"
                              offset={1}
                              position="insideBottom"
                              dy={15}
                            />
                          </XAxis>
                          <YAxis
                            dataKey="MER"
                            label={{
                              value: "Minimum Expected Return (%)",
                              angle: -90,
                              position: "Left",
                            }}
                          />
                          <Tooltip />
                          {/* <Legend /> */}
                          <Line
                            type="monotone"
                            dataKey="MER"
                            stroke="#8F8F8F"
                            strokeWidth={3}
                            activeDot={{ r: 4 }}
                          />
                        </LineChart>
                      </div>
                    </Grid>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="wrapGrids">
                <TableContainer
                  component={Paper}
                  style={{ marginTop: "200px", border: "none" }}
                >
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow>
                        <TableCell style={{ color: "white" }}>Asset</TableCell>
                        <TableCell style={{ color: "white" }}>Weight</TableCell>
                        <TableCell style={{ color: "white" }}>LB</TableCell>
                        <TableCell style={{ color: "white" }}>UB</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {graphGeneraInfoData.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.asset}</TableCell>
                            <TableCell>{indv.weight}</TableCell>
                            <TableCell>{indv.lb}</TableCell>
                            <TableCell>{indv.ub}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {/* {children} */}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className="container">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={4} className="wrapGrids" spacing={3}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Optimized Portfolio
                </Typography>
                <div style={{ paddingRight: "15px" }}>
                  <Form className="labelForm">
                    <Grid container>
                      <label>Expected Return</label>
                      <TextInput source="Expected Return" fullWidth />
                      <label>MR</label>
                      <TextInput source="MR" fullWidth />
                      <label>Volatility</label>
                      <TextInput source="Volatility" fullWidth />
                      <label>EMD</label>
                      <TextInput source="EMD" fullWidth />
                      <label>Liquidity</label>
                      <TextInput source="Liquidity" fullWidth />

                      <h3>Status: Optimized</h3>
                    </Grid>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={8} className="wrapGridsLower">
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Options
                </Typography>
                <div className="row-custom">
                  <div className="col" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Objective</label>
                        <SelectInput
                          source="Select Objective…"
                          choices={[
                            {
                              id: "0",
                              name: "Maximize Expected Return by Setting Volatility",
                            },
                            {
                              id: "1",
                              name: "Minimize Volatility By Setting Expected Return",
                            },
                            { id: "2", name: "Setting Minimum Return To" },
                            { id: "3", name: "Maximize Minimum Return" },
                          ]}
                          fullWidth
                        />
                        <label>Lower Limit Liquidity (%)</label>
                        <TextInput
                          source="Lower Limit Liquidity (%)"
                          fullWidth
                        />
                      </Grid>
                    </Form>
                  </div>
                  <div className="col" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Amount</label>
                        <TextInput source="Amount" fullWidth />
                        <label>Upper Limit Liquidity (%)</label>
                        <TextInput
                          source="Upper Limit Liquidity (%)"
                          fullWidth
                        />
                      </Grid>
                    </Form>
                  </div>
                  <div className="col" style={{ paddingLeft: "30px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Escrow Cash (%)</label>
                        <TextInput source="Escrow Cash (%)" fullWidth />
                        <label>EMD Not Exceed % Efficient Frontier</label>
                        <TextInput
                          source="EMD Not Exceed % Efficient Frontier"
                          fullWidth
                        />
                      </Grid>
                    </Form>
                  </div>
                </div>

                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Efficient Frontier Limits
                </Typography>
                <div className="rows" style={{ display: "flex" }}>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label></label>
                        <input
                          type="Button"
                          className="minMaxButton"
                          value="Max"
                        />
                        <input
                          type="Button"
                          className="minMaxButton"
                          value="Min"
                        />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Expected Return</label>
                        <TextInput source="Expected Return" fullWidth />
                        <TextInput source="Expected Return" fullWidth />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "10px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Volatility</label>
                        <TextInput source="Volatility" fullWidth />
                        <TextInput source="Volatility" fullWidth />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingRight: "30px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Minimum Return</label>
                        <TextInput source="Minimum Return" fullWidth />
                        <TextInput source="Minimum Return" fullWidth />
                      </Grid>
                    </Form>
                  </div>
                  <div className="row" style={{ paddingLeft: "30px" }}>
                    <Form className="labelForm">
                      <Grid container>
                        <label>Expected MD</label>
                        <TextInput source="Expected MD" fullWidth />
                        <TextInput source="Expected MD" fullWidth />
                      </Grid>
                    </Form>
                  </div>
                </div>

                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Capital Market Estimates and Constraints
                </Typography>
                <TableContainer component={Paper} style={{ border: "none" }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow style={{ color: "white" }}>
                        <TableCell style={{ color: "white" }}>Name</TableCell>
                        <TableCell style={{ color: "white" }}>
                          Exp. Return
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Volatility
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Liquidity
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Min Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Max Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Optimal Weight
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {capitalMarketData.map((indv: any, indxx: number) => {
                        return (
                          <TableRow style={{ height: "50px" }}>
                            <TableCell>{indv.name}</TableCell>
                            <TableCell>{indv.expReturn}</TableCell>
                            <TableCell>{indv.volatility}</TableCell>
                            <TableCell>{indv.liquidity}</TableCell>
                            <TableCell>{indv.minWeight}</TableCell>
                            <TableCell>{indv.maxWeight}</TableCell>
                            <TableCell>{indv.optimalWeight}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {/* {children} */}
                  </Table>
                </TableContainer>

                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Firm Wide Constraints
                </Typography>
                <TableContainer component={Paper} style={{ border: "none" }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "grey" }}>
                      <TableRow style={{ color: "white" }}>
                        <TableCell style={{ color: "white" }}>Name</TableCell>

                        <TableCell style={{ color: "white" }}>
                          Min Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Max Weight
                        </TableCell>
                        <TableCell style={{ color: "white" }}>
                          Weight Based on
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {firmWideConstraintsData.map(
                        (indv: any, indxx: number) => {
                          return (
                            <TableRow style={{ height: "50px" }}>
                              <TableCell>{indv.name}</TableCell>
                              <TableCell>{indv.minWeight}</TableCell>
                              <TableCell>{indv.maxWeight}</TableCell>
                              <TableCell>{indv.weightBasedon}</TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                    {/* {children} */}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card style={{ marginTop: "20px" }}>
        <Box display="flex">
          <Box flex="1">
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              style={{ padding: "20px 20px" }}
            >
              {translate("pos.modelBuilder.header.title")}
            </Typography>
          </Box>
          <Mainmenu />
        </Box>
      </Card>

      <CardActions
        sx={{
          padding: { xs: 0, xl: null },
          flexWrap: { xs: "wrap", xl: null },
          "& a": {
            marginTop: { xs: "1em", xl: null },
            marginLeft: { xs: "0!important", xl: null },
            marginRight: { xs: "1em", xl: null },
          },
        }}
      >
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.estimate_button")}
        </Button>
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.optimize_button")}
        </Button>

        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.choose_button")}
        </Button>

        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.sensitivity_button")}
        </Button>
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.export_button")}
        </Button>
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.ranking_button")}
        </Button>
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.saveas_button")}
        </Button>
        <Button
          style={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.modelBuilder.buttons.save_button")}
        </Button>
      </CardActions>

      <TabbedDatagrid />
    </>
  );
});

export default ModuleBuilder;
