import React from "react";
import GeneralInformation from "./GeneralInformation";
import Topbar from "./Topbar";
import "./goalEditor.css";
import Matching from "./Matching";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Wealth from "./Wealth";
import Cashflow from "./Cashflow";
import Tables from "./Tables";

const GoalEditor = () => {
  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex", flexDirection: "column" },
    leftCol: { flex: 1, marginRight: "0.5em" },
    rightCol: { flex: 1, marginLeft: "0.5em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };

  return (
    <>
      <Topbar />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <GeneralInformation />
          </Grid>
          <Grid item xs={6}>
             
            <Matching />
           
            <Tables/>
           
           
          </Grid>
           <Grid item xs={3}>
              <Wealth />
               <Cashflow />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GoalEditor;
