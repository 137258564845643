import React from "react";
import {
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Typography,
  List,
  Card,
} from "@mui/material";

import FormGroup from "@mui/material/FormGroup";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Checkbox from "@mui/material/Checkbox";
import { CheckboxGroupInput } from "react-admin";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AutocompleteInput,
  DateInput,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  useGetList,
  useListContext,
  FileInput,
  ImageField,
  useTranslate,
  Form,
  SelectInput,
  SaveButton,
  useRecordContext,
  RadioButtonGroupInput,
} from "react-admin";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Additional = () => {
  const [objective, setObjective] = React.useState("");
  const [btndisabled, setbtnDisabled] = React.useState(false);

  const objectiveHandleChange = (event: SelectChangeEvent) => {
    setObjective(event.target.value as string);
  };
  const handleDisable = () => {
    setbtnDisabled(true);
  };
  const handleEnable = () => {
    setbtnDisabled(false);
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>

    <Card style={{ marginTop: "2px" }}>
      <Box display="flex">
        <Box flex="1">
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            style={{ padding: "10px 10px" }}
          >
            Additional Options
            </Typography>
          <Grid sx={{ ml: 2, mr: 2, mt: -2 }}>
            <List dense={false}>
              <Grid container spacing={1}>
                <Grid item xs={3}>

                  <FormControl>
                    <label>Lower Limit Liquidity (%)</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>

                  <FormControl>
                    <label>Upper Limit Liquidity (%)</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>

                  <FormControl>
                    <label>Escrow Cash (%)</label>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      defaultValue=""
                      variant="filled"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Form>
                    <Grid sx={{ mt: -4 }}>
                     <Checkbox {...label} />
                      <label>EMD Not Exceed % Efficient Frontier</label>
                      <TextField

                        hiddenLabel
                        id="filled-hidden-label-small"

                        variant="filled"
                        size="small"
                        label=""
                      />
                    </Grid>
                  </Form>
                </Grid>

              


              </Grid>


            </List>
          </Grid>
        </Box>
      </Box>
    </Card>

     
    </>
  );
};

export default Additional;


