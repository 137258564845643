const DB_GROUP = [
  {
    name: "Asset Database 2020",
    id: 1,
  },
  {
    name: "Asset Database 2019",
    id: 2,
  },
];

const ASSET_DB = [
  {
    name: "AT Database 2020",
    id: 1,
    db_group_id: 1,
  },
  {
    name: "KIA Database 2020",
    id: 2,
    db_group_id: 1,
  },
  {
    name: "SG Database 2020",
    id: 3,
    db_group_id: 1,
  },
  {
    name: "Snapshot 1 of KIA Database",
    id: 4,
    db_group_id: 2,
  },
  {
    name: "SG Database 2019",
    id: 5,
    db_group_id: 2,
  },
  {
    name: "AT Database 2019",
    id: 6,
    db_group_id: 2,
  },
];

const CLIENTS = [
  {
    name: "Agarwal Family - Lorem - 2020",
    id: 1,
    asset_db_id: 1,
    currency: "$10m",
    ca: "C:0,A:1",
    children: [
      {
        name: "Brother Family 2020",
        id: 1,
        currency: "$5m",
        ca: "C:0,A:1",
      },
      {
        name: "Sister Family 2020",
        id: 2,
        currency: "$5m",
        ca: "C:0,A:1",
      },
    ],
  },
  {
    name: "Agarwal Family - sit - 2020",
    id: 2,
    asset_db_id: 2,
    currency: "$10m",
    ca: "C:0,A:1",
    children: [
      {
        name: "Brother Family 2020",
        id: 1,
        currency: "$5m",
        ca: "C:0,A:1",
      },
      {
        name: "Sister Family 2020",
        id: 2,
        currency: "$5m",
        ca: "C:0,A:1",
      },
    ],
  },
  {
    name: "Agarwal Family - Lorem - 2019",
    id: 3,
    asset_db_id: 4,
    currency: "$10m",
    ca: "C:0,A:1",
    children: [
      {
        name: "Brother Family 2019",
        id: 1,
        currency: "$5m",
        ca: "C:0,A:1",
      },
      {
        name: "Sister Family 2019",
        id: 2,
        currency: "$5m",
        ca: "C:0,A:1",
      },
    ],
  },
  {
    name: "Agarwal Family - sit - 2019",
    id: 2,
    asset_db_id: 5,
    currency: "$10m",
    ca: "C:0,A:1",
    children: [
      {
        name: "Brother Family 2019",
        id: 1,
        currency: "$5m",
        ca: "C:0,A:1",
      },
      {
        name: "Sister Family 2019",
        id: 2,
        currency: "$5m",
        ca: "C:0,A:1",
      },
    ],
  },
];

const getAssetDbByDbGroupId = (dbGroupId: number) =>
  ASSET_DB.filter((indv, indx) => indv.db_group_id === dbGroupId);

const getClientsByAssetDb = (assetDbId: number) =>
  CLIENTS.filter((indv, indx) => indv.asset_db_id === assetDbId);

export { DB_GROUP, getAssetDbByDbGroupId, getClientsByAssetDb };
