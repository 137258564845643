import * as React from "react";
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
  Grid,
  List,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { TextInput, SelectInput, useTranslate } from "react-admin";
import Mainmenu from "../components/Mainmenu";
import Addnewconstraint from "./Addnewconstraint";
import Subefficientfrontier from "./Subefficientfrontier";
import Subsensitivity from "./Subsensitivity";
import Showranking from "./Showranking";
import publishArticleImage from "./welcome_illustration.svg";
import "./moduleEdit.css";

const Buttons = () => {
  const translate = useTranslate();

  const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex" },
    leftCol: { flex: 1, marginRight: "10em" },
    rightCol: { flex: 1, marginLeft: "10em" },
    singleCol: { marginTop: "1em", marginBottom: "1em" },
  };
  return (
    <>
      <CardActions
        sx={{
          padding: { xs: 0, xl: null },
          flexWrap: { xs: "wrap", xl: null },
          "& a": {
            marginTop: { xs: "1em", xl: null },
            marginLeft: { xs: "0!important", xl: null },
            marginRight: { xs: "1em", xl: null },
          },
        }}
      >
        <Addnewconstraint />

        <Button sx={{ color: "black" }} variant="outlined" href="#">
          {translate("pos.tables.buttons.estimate_frontier_btn")}
        </Button>

        <Button sx={{ color: "black" }} variant="outlined" href="#">
          {translate("pos.tables.buttons.optimize_portfolio_btn")}
        </Button>

        <Subefficientfrontier />

        <Subsensitivity />

        <Button sx={{ color: "black" }} variant="outlined" href="#">
          {translate("pos.tables.buttons.save_module_btn")}
        </Button>

        <Button sx={{ color: "black" }} variant="outlined" href="#">
          {translate("pos.tables.buttons.save_as_new_module_btn")}
        </Button>

        {/* <Button
          sx={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.tables.buttons.export_to_excel_btn")}
        </Button> */}

        <Showranking />

        {/* <Button
          sx={{ color: "black" }}
          variant="outlined"
          href="#"
        >
          {translate("pos.tables.buttons.close_btn")}
        </Button> */}
      </CardActions>
    </>
  );
};

export default Buttons;
