// import * as React from 'react';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import {
//   useMediaQuery,
//   Theme,



//   FormControl,
//   FormLabel,
//   Card,
// } from "@mui/material";
// import {
//   Box,

//   CardActions,


//   Grid,
//   List,
// } from "@mui/material";

// import { useTranslate } from "react-admin";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";


// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// export interface DialogTitleProps {
//   id: string;
//   children?: React.ReactNode;
//   onClose: () => void;
// }

// function BootstrapDialogTitle(props: DialogTitleProps) {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

// export default function Showranking() {
//   const [term, setTerm] = React.useState("");
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const termHandleChange = (event: SelectChangeEvent) => {
//     setTerm(event.target.value as string);
//   };




//   const firmtable = [
//     {
//       id: 1,
//       name: "Module 23",
//       expreturn: "1.69%",
//       volatility: "1.15%",
//       horizon: "20",
//       probability: "95",
//       minreturn: "1.26%",
//       taxable: "False",
//       year: "1.25%"
//     },

//   ]

//   return (
//     <div>
//       <Button className='btn-showranking'
//         variant="outlined" sx={{ color: "black", mt: 2, mr: 2 }} onClick={handleClickOpen}>
//         Show Ranking
//       </Button>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//       >
//         <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//           Ranking
//         </BootstrapDialogTitle>
//         <DialogContent >

//           <FormControl>
//             <FormLabel>Group By</FormLabel>
//             <Select
//               className="addconstraint-drop"
//               labelId="demo-simple-select-label"
//               id="outlined-basic"
//               // variant="outlined"
//               value={term}
//               label="Age"
//               onChange={termHandleChange}
//             >
//               <MenuItem value={10}>value 1</MenuItem>
//             </Select>
//           </FormControl>
//           <Card style={{ marginTop: "20px" }}>
//             <Box display="flex">
//               <Box flex="1">

//                 <Grid sx={{ ml: 3, mr: 3 }}>
//                   <List dense={false}>
//                     <TableContainer component={Paper} style={{ border: "none" }}>
//                       <Table aria-label="simple table">
//                         <TableHead style={{ backgroundColor: "grey" }}>
//                           <TableRow style={{ color: "white" }}>
//                             <TableCell style={{ color: "white" }}>Name</TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Exp.Return
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Volatility
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Horizon
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Probability
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Min Return
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Taxable
//                             </TableCell>
//                             <TableCell style={{ color: "white" }}>
//                               Year 20
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {firmtable.map((indv: any, indxx: number) => {
//                             return (
//                               <TableRow style={{ height: "50px" }}>
//                                 <TableCell>{indv.name}</TableCell>
//                                 <TableCell>{indv.expreturn}</TableCell>
//                                 <TableCell>{indv.volatility}</TableCell>
//                                 <TableCell>{indv.horizon}</TableCell>
//                                 <TableCell>{indv.probability}</TableCell>
//                                 <TableCell>{indv.minreturn}</TableCell>
//                                 <TableCell>{indv.taxable}</TableCell>
//                                 <TableCell>{indv.year}</TableCell>
//                               </TableRow>
//                             );
//                           })}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </List>
//                 </Grid>
//               </Box>
//             </Box>
//           </Card>

//         </DialogContent>
//         <DialogActions>

//         </DialogActions>
//       </BootstrapDialog>
//     </div>
//   );
// }

import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardActions, Typography, Grid, List } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Modal, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper } from "@mui/material";
import "./moduleEdit.css";

import MenuItem from "@mui/material/MenuItem";
import {
  useMediaQuery,
  Theme,
  FormControl,
  FormLabel,

} from "@mui/material";

export default function Showranking() {





  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
  };




  const [open, setOpen] = React.useState(false);
  const [term, setTerm] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const termHandleChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };


  const firmtable = [
    {
      id: 1,
      name: "Module 23",
      expreturn: "1.69%",
      volatility: "1.15%",
      horizon: "20",
      probability: "95",
      minreturn: "1.26%",
      taxable: "False",
      year: "1.25%"
    },

  ]

  return (

    <div>
      <Button
        variant="outlined" sx={{ color: "black", mt: 2, mr: 1 }} onClick={handleClickOpen}>
        Show Ranking
      </Button>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >

        <Box sx={style}>
          <h3> Ranking</h3>

          <Grid container spacing={4} sx={{ mt: 3, ml: 1, mr: 3 }}>


            <FormControl>
              <FormLabel>Group By</FormLabel>
              <Select
                className="addconstraint-drop"
                labelId="demo-simple-select-label"
                id="outlined-basic"
                // variant="outlined"
                value={term}
                label="Age"
                onChange={termHandleChange}
              >
                <MenuItem value={10}>value 1</MenuItem>
              </Select>
            </FormControl>



            <Card style={{ marginTop: "20px" }}>
              <Box display="flex">
                <Box flex="1">

                  <Grid sx={{ ml: 3, mr: 3 }}>
                    <List dense={false}>
                      <TableContainer component={Paper} style={{ border: "none" }}>
                        <Table aria-label="simple table">
                          <TableHead style={{ backgroundColor: "grey" }}>
                            <TableRow style={{ color: "white" }}>
                              <TableCell style={{ color: "white" }}>Name</TableCell>
                              <TableCell style={{ color: "white" }}>
                                Exp.Return
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Volatility
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Horizon
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Probability
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Min Return
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Taxable
                             </TableCell>
                              <TableCell style={{ color: "white" }}>
                                Year 20
                             </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {firmtable.map((indv: any, indxx: number) => {
                              return (
                                <TableRow style={{ height: "50px" }}>
                                  <TableCell>{indv.name}</TableCell>
                                  <TableCell>{indv.expreturn}</TableCell>
                                  <TableCell>{indv.volatility}</TableCell>
                                  <TableCell>{indv.horizon}</TableCell>
                                  <TableCell>{indv.probability}</TableCell>
                                  <TableCell>{indv.minreturn}</TableCell>
                                  <TableCell>{indv.taxable}</TableCell>
                                  <TableCell>{indv.year}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </List>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Box>
      </Modal>

    </div>

  );
}



