import React, { BaseSyntheticEvent, useState } from "react";
import { TextField } from "@mui/material";

interface IReturnInputField {
  initialValue: string;
  returnChange: (value: string) => void;
  getEventValueAndResetEditField: (value: string) => void;
  id: string;
}
export const ReturnInputField = ({
  initialValue,
  returnChange,
  getEventValueAndResetEditField,
  id,
}: IReturnInputField) => {
  const [value, setValue] = useState(initialValue);
  return (
    <TextField
      variant="outlined"
    //   label={id + "-field"}
      id={id + "-field"}
      value={value}
      onFocus={() => console.log("focused")}
      onBlur={(e: BaseSyntheticEvent) => {
        console.log("blurred", id + "-field", e.target.value);
        getEventValueAndResetEditField(e.target.value);
      }}
      onChange={(event: BaseSyntheticEvent) => {
        setValue(event.target.value);
        returnChange(event.target.value);
        console.log("event", event.target.value);
      }}
    />
  );
};
